import { FC } from "react";

import { Alert, Snackbar } from "@mui/material";

interface ErrorNotificationProps {
    error?: string;
    clearError: () => void;
}

/**
 * Error Notification handler for use within the AuthModal
 *
 * @param error
 * @param clearError
 */
export const ErrorNotification: FC<ErrorNotificationProps> = ({ error, clearError }) => {
    return error ? (
        <Snackbar
            open={!!error}
            autoHideDuration={5000}
            onClose={(_, reason) => {
                if (reason === "clickaway") return;
                clearError();
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={{ width: "90%" }}
        >
            <Alert severity="error" variant="filled" sx={{ width: "100%" }}>
                {error}
            </Alert>
        </Snackbar>
    ) : null;
};
