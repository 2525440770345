import { getAuth } from "firebase/auth";
import { enqueueSnackbar } from "notistack";

export const isGuest = () => {
    const auth = getAuth();
    return !auth.currentUser || auth.currentUser.isAnonymous;
};

export const isAuthorized = async (response: Response) => {
    if (response.status === 401 || response.status === 403) {
        const auth = getAuth();
        enqueueSnackbar("Your session has expired. Please log in again.", { variant: "error" });
        await auth.signOut();
    }
};
