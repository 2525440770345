import type { PaletteColorOptions, ThemeOptions } from "@mui/material/styles";
import { ReferenceFields } from "honeygrid-types";

interface BasePalette {
    [ReferenceFields.CustomerCount]: PaletteColorOptions;
    [ReferenceFields.CustomerSales]: PaletteColorOptions;
    [ReferenceFields.CustomerMarketShare]: PaletteColorOptions;
    [ReferenceFields.Households]: PaletteColorOptions;
    [ReferenceFields.HomeValues]: PaletteColorOptions;
    [ReferenceFields.Population]: PaletteColorOptions;
    [ReferenceFields.Income]: PaletteColorOptions;
    [ReferenceFields.Age]: PaletteColorOptions;
    [ReferenceFields.PercentOwnerOccupied]: PaletteColorOptions;
    [ReferenceFields.PercentHouseholdWithChildren]: PaletteColorOptions;
    [ReferenceFields.PercentHispanic]: PaletteColorOptions;
    [ReferenceFields.NewMoverTotal]: PaletteColorOptions;
    [ReferenceFields.NewMoverSFDU]: PaletteColorOptions;
    [ReferenceFields.NewMoverMFDU]: PaletteColorOptions;
    [ReferenceFields.Businesses]: PaletteColorOptions;
    [ReferenceFields.SFDU]: PaletteColorOptions;
    [ReferenceFields.MFDU]: PaletteColorOptions;
}

interface BasePropsColorOverrides {
    [ReferenceFields.CustomerCount]: true;
    [ReferenceFields.CustomerSales]: true;
    [ReferenceFields.CustomerMarketShare]: true;
    [ReferenceFields.Households]: true;
    [ReferenceFields.HomeValues]: true;
    [ReferenceFields.Population]: true;
    [ReferenceFields.Income]: true;
    [ReferenceFields.Age]: true;
    [ReferenceFields.PercentOwnerOccupied]: true;
    [ReferenceFields.PercentHouseholdWithChildren]: true;
    [ReferenceFields.PercentHispanic]: true;
    [ReferenceFields.NewMoverTotal]: true;
    [ReferenceFields.NewMoverSFDU]: true;
    [ReferenceFields.NewMoverMFDU]: true;
    [ReferenceFields.Businesses]: true;
    [ReferenceFields.SFDU]: true;
    [ReferenceFields.MFDU]: true;
}

declare module "@mui/material/styles" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface Palette extends BasePalette {}
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface PaletteOptions extends BasePalette {}
}

declare module "@mui/material/Button" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ButtonPropsColorOverrides extends BasePropsColorOverrides {}
}

declare module "@mui/material/ButtonGroup" {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface ButtonGroupPropsColorOverrides extends BasePropsColorOverrides {}
}

export const referenceFieldColors = {
    [ReferenceFields.CustomerCount]: {
        main: "#792121",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.CustomerSales]: {
        main: "#2E623F",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.CustomerMarketShare]: {
        main: "#A65900",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.Households]: {
        main: "#464A80",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.HomeValues]: {
        main: "#235D6A",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.Population]: {
        main: "#792121",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.Income]: {
        main: "#464A80",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.Age]: {
        main: "#2E623F",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.PercentOwnerOccupied]: {
        main: "#A65900",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.PercentHouseholdWithChildren]: {
        main: "#6D306B",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.PercentHispanic]: {
        main: "#235D6A",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.NewMoverTotal]: {
        main: "#792121",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.NewMoverSFDU]: {
        main: "#A65900",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.NewMoverMFDU]: {
        main: "#2E623F",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.Businesses]: {
        main: "#A65900",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.SFDU]: {
        main: "#6D306B",
        contrastText: "#FFFFFF",
    },
    [ReferenceFields.MFDU]: {
        main: "#235D6A",
        contrastText: "#FFFFFF",
    },
    default: {
        main: "#792121",
        contrastText: "#FFFFFF",
    },
} as const;

export enum Theme {
    Light = "light",
    Dark = "dark",
}

export const MAP_THEMES = {
    [Theme.Light]: "mapbox://styles/honeygrid/cllfbfowr00gj01pu1fgsc7o4",
    [Theme.Dark]: "mapbox://styles/honeygrid/clkv8xaa8001601q0ba0r3y9r",
};

export const componentOverrides: ThemeOptions["components"] = {
    MuiButton: {
        styleOverrides: {
            root: {
                textTransform: "none",
                borderRadius: "1000px",
            },
            outlined: {
                "borderWidth": "2px",
                "&:hover": {
                    borderWidth: "2px",
                },
            },
        },
    },
    MuiTypography: {
        styleOverrides: {
            h1: {
                letterSpacing: "-1.5px",
                fontWeight: "600",
            },
            h2: {
                letterSpacing: "-0.5px",
                fontWeight: "600",
            },
            h4: {
                letterSpacing: "0.25px",
            },
            h6: {
                fontWeight: "600",
                letterSpacing: "0.15px",
            },
            subtitle1: {
                letterSpacing: "0.15px",
                fontWeight: "600",
            },
            subtitle2: {
                letterSpacing: "0.1px",
                fontWeight: "600",
            },
            body1: {
                letterSpacing: "0.15px",
            },
            body2: {
                letterSpacing: "0.15px",
            },
        },
    },
};

export const lightThemeOptions: ThemeOptions = {
    palette: {
        mode: "light",
        primary: {
            main: "#621984",
            contrastText: "rgba(255, 255, 255, 1)",
            dark: "#44115C",
            light: "#81479C",
        },
        secondary: {
            main: "#235D6A",
            contrastText: "rgba(255, 255, 255, 1)",
            dark: "#18414A",
            light: "#4F7D87",
        },
        text: {
            primary: "#3A2E2F",
            secondary: "#938586",
            disabled: "#C8C2C3",
        },
        error: {
            main: "#792121",
            dark: "#541717",
            light: "#934D4D",
        },
        warning: {
            main: "#A65900",
            contrastText: "rgba(255, 255, 255, 1)",
            dark: "#743E00",
            light: "#B77A33",
        },
        info: {
            main: "#464A80",
            dark: "#313359",
            light: "#6B6E99",
        },
        success: {
            main: "#2E623F",
            contrastText: "#182A33",
            light: "#578165",
            dark: "#20442C",
        },
        divider: "rgba(58, 46, 47, 0.42)",
        action: {
            hover: "rgba(98, 25, 132, 0.08)",
            hoverOpacity: 0.08,
            focus: "#81479C",
            focusOpacity: 0.12,
            selected: "#3A2E2F",
            selectedOpacity: 0.08,
        },
        background: {
            default: "rgba(255, 255, 255, 1)",
            paper: "rgba(251, 248, 248, 1)",
        },
        ...referenceFieldColors,
    },
    typography: {
        fontFamily: "Telegraf",
        subtitle1: {
            fontWeight: 600,
        },
    },
    components: componentOverrides,
};

export const darkThemeOptions: ThemeOptions = {
    palette: {
        mode: "dark",
        primary: {
            main: "#937F9D",
            contrastText: "#FFFFFF",
            light: "#B1A0BA",
            dark: "#795F85",
        },
        secondary: {
            main: "#B8BEFE",
            contrastText: "rgba(103.51, 94.03, 94.96, 1)",
            dark: "#8085B1",
            light: "#C6CBFE",
        },
        text: {
            primary: "#FFFFFF",
            secondary: "rgba(255, 255, 255, 0.7)",
            disabled: "rgba(255, 255, 255, 0.5)",
        },
        error: {
            main: "#E32458",
            dark: "#9E193D",
            light: "#E84F79",
        },
        warning: {
            main: "#F78D1E",
            contrastText: "#182A33",
            dark: "#F57C00",
            light: "#F8A34B",
        },
        info: {
            main: "#4547A8",
            dark: "#303175",
            light: "#6A6BB9",
        },
        success: {
            main: "#67C599",
            contrastText: "#182A33",
            light: "#85D0AD",
            dark: "#48896B",
        },
        divider: "rgba(255, 255, 255, 0.12)",
        action: {
            active: "rgba(121, 95, 133, 0.30)",
            activatedOpacity: 0.3,
            hover: "rgba(121, 95, 133, 0.16)",
            hoverOpacity: 0.16,
            focus: "#795F85",
            focusOpacity: 0.12,
            selected: "#FFFFFF",
            selectedOpacity: 0.16,
            disabled: "#937F9D",
            disabledOpacity: 0.3,
            disabledBackground: "#FFFFFF",
        },
        background: {
            default: "#292224",
            paper: "#292224",
        },
        ...referenceFieldColors,
    },
    typography: {
        fontFamily: "Telegraf",
    },
    components: componentOverrides,
};

export const themeOptionsMap = {
    [Theme.Light]: lightThemeOptions,
    [Theme.Dark]: darkThemeOptions,
};
