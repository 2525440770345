import {
    addDoc,
    collection,
    doc,
    documentId,
    getDoc,
    getDocs,
    getFirestore,
    query,
    setDoc,
    updateDoc,
    where,
} from "firebase/firestore";
import { SystemGeneratedString, User } from "honeygrid-types";

import { Collections } from "../collections";
import { userConverter } from "./converters";
import { Builder } from "./types";

export const usersEndpoints = (builder: Builder, firestore = getFirestore()) => ({
    /**
     * A query that retrieves all users from the Firestore database.
     * @param void
     * @returns {ReturnType<typeof createApi>["endpoints"]["getUsers"]} The RTK Query endpoint for retrieving a list of users.
     */
    getUsers: builder.query<User[], void>({
        queryFn: async () => {
            const usersCollection = collection(firestore, Collections.Users).withConverter(userConverter);
            const usersSnapshot = await getDocs(usersCollection);
            const users: User[] = [];
            usersSnapshot.forEach(doc => {
                users.push(doc.data());
            });
            return { data: users };
        },
        providesTags: [Collections.Users],
    }),
    /**
     * A query that retrieves a list of users from the Firestore database.
     * @param {string[]} userIds The list of users to retrieve
     * @returns {ReturnType<typeof createApi>["endpoints"]["getUsers"]} The RTK Query endpoint for retrieving a list of users.
     */
    getUsersById: builder.query<User[], string[]>({
        queryFn: async userIds => {
            if (!userIds || userIds.length === 0) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore This is causing backend to break and it's not important functionality
                if (import.meta.env.DEV) console.log("No userIds provided");
                return { data: [] as User[] };
            }

            const usersCollection = collection(firestore, Collections.Users).withConverter(userConverter);
            const usersQuery = query(usersCollection, where(documentId(), "in", userIds));
            const usersSnapshot = await getDocs(usersQuery);
            const users: User[] = [];
            usersSnapshot.forEach(doc => {
                users.push(doc.data());
            });
            return { data: users };
        },
        providesTags: [Collections.Users],
    }),
    /**
     * A query that retrieves a single user from the Firestore database by their ID.
     *
     * @param {string} userId The ID of the user to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getUserById"]} The RTK Query endpoint for retrieving a single user by ID.
     */
    getUserById: builder.query<User, string>({
        queryFn: async id => {
            const userDoc = doc(firestore, Collections.Users, id).withConverter(userConverter);
            const user = await getDoc(userDoc);
            return { data: user.data() as User };
        },
        providesTags: (_result, _error, id) => [{ type: Collections.Users, id }],
    }),
    /**
     * A mutation that updates a user in the Firestore database.
     *
     * @param {Partial<User>} user The updated user object.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateUser"]} The RTK Mutation endpoint for updating a user.
     * @invalidates {Collections.Users} Invalidates the `Collections.Users` tag.
     * @invalidates {Collections.Users, { type: Collections.Users, id: user.id }} Invalidates the specific user being updated.
     */
    updateUser: builder.mutation<Pick<User, "id">, Partial<User>>({
        queryFn: async user => {
            const saveUser = { ...user };
            const userDoc = doc(firestore, Collections.Users, user.id ?? "").withConverter(userConverter);
            await updateDoc(userDoc, saveUser);
            return { data: { id: user.id } as Pick<User, "id"> };
        },
        invalidatesTags: (_result, _error, user) => [Collections.Users, { type: Collections.Users, id: user.id }],
    }),
    /**
     * A mutation that creates a new user in the Firestore database.
     *
     * @param {Partial<User>} user The user object to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createUser"]} The RTK Mutation endpoint for creating a new user.
     * @invalidates {Collections.Users} Invalidates the `Collections.Users` tag.
     */
    createUser: builder.mutation<User, { user: Partial<User>; userId?: SystemGeneratedString }>({
        queryFn: async ({ user, userId }) => {
            const saveUser = { ...user };
            let uid = userId;

            const usersCollection = collection(firestore, Collections.Users).withConverter(userConverter);
            if (userId) {
                await setDoc(doc(usersCollection, userId), saveUser);
            } else {
                const docRef = await addDoc(usersCollection, saveUser);
                uid = docRef.id;
            }

            const newUser = { ...user, uid: uid } as User;
            return { data: newUser };
        },
        invalidatesTags: [Collections.Users],
    }),
});
