import { type FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Routes as NamedRoutes } from "@/routes";
import { Grid } from "@mui/material";
import { useAppDispatch } from "@redux/hooks/useAppState";
import { getAuth } from "firebase/auth";

import HomePageBackground from "../../assets/images/HomePageBackground.png";
import { SignUpForm } from "../Auth";
import { SignInForm } from "../Auth/SignInForm";

export const LoginScreen: FC = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [dispatch, actions] = useAppDispatch();
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        if (auth.currentUser) {
            navigate(NamedRoutes.Dashboard, { replace: true });
        } else {
            dispatch(actions.setIsProfileButtonShown(false));
        }
    }, [auth.currentUser, navigate, dispatch, actions]);

    return (
        <Grid
            container
            sx={{
                flexGrow: 1,
                minHeight: 0,
                height: "calc(100vh - 64px)",
                backgroundImage: `url(${HomePageBackground})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center center",
            }}
        >
            <Grid
                item
                container
                xs={12}
                sx={{ height: "fit-content" }}
                display="flex"
                flexDirection="column"
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
                paddingTop="20vh"
            >
                <Grid
                    item
                    sx={{
                        borderRadius: 2,
                        backgroundColor: theme => theme.palette.background.paper,
                        p: 2,
                    }}
                >
                    {isLogin ? (
                        <SignInForm toggleView={() => setIsLogin(false)} redirectUrl={NamedRoutes.Dashboard} />
                    ) : (
                        <SignUpForm onSignInClicked={() => setIsLogin(true)} redirectUrl={NamedRoutes.Dashboard} />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
