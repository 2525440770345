import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { User } from "firebase/auth";

import { FeatureNames } from "../names";

export interface AuthState {
    user: User | null;
    isLoginDialogOpen: boolean;
}

const initialState: AuthState = {
    user: null,
    isLoginDialogOpen: false,
};

export const authSlice = createSlice({
    name: FeatureNames.Auth,
    initialState,
    reducers: {
        setIsLoginDialogOpen: (state, action: PayloadAction<boolean>) => {
            state.isLoginDialogOpen = action.payload;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
        logOut: () => {
            return initialState;
        },
    },
});

export type AuthActions = typeof authSlice.actions;
