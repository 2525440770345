import { FC } from "react";

import { Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, Grid, IconButton, Typography } from "@mui/material";

interface IllustratedDialogProps {
    isOpen: boolean;
    onDismiss: () => void;
    imageSource: string;
    title: string;
    description?: string;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    primaryAction?: {
        label: string;
        onClick: () => void;
    };
    children?: React.ReactNode;
}

export const IllustratedDialog: FC<IllustratedDialogProps> = ({
    isOpen,
    onDismiss,
    imageSource,
    title,
    description,
    primaryAction,
    children,
    maxWidth = "xs",
}) => {
    return (
        <Dialog open={isOpen} onClose={onDismiss} fullWidth maxWidth={maxWidth}>
            <DialogContent>
                <Grid container alignItems={"center"} flexDirection={"column"} rowSpacing={4}>
                    <Grid item container justifyContent={"flex-end"} rowSpacing={1}>
                        <IconButton onClick={onDismiss} aria-label="close">
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems={"center"} mt={-4}>
                            <img src={imageSource} alt="image" />
                            <Typography mt={2} variant="h5" textAlign={"center"}>
                                {title}
                            </Typography>
                            {description && (
                                <Typography mt={1} variant="body1" textAlign={"center"}>
                                    {description}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    {children}
                    {primaryAction && (
                        <Grid item container>
                            <Button variant="contained" onClick={primaryAction.onClick} sx={{ width: "100%" }}>
                                {primaryAction.label}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
