import { collection, doc, getDocs, getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { CustomData, UploadCustomDataRequest, UploadCustomDataResponse } from "honeygrid-types";
import { enqueueSnackbar } from "notistack";

import { Collections } from "../collections";
import { customDataConverter } from "./converters";
import { Builder } from "./types";

export const customDataEndpoints = (
    builder: Builder,
    apiUrl?: string,
    firestore = getFirestore(),
    functions = getFunctions(),
) => ({
    /**
     * A query that retrieves all customData cells for a given workspace from the Firestore database.
     * @param {{ workspaceId: string }} params The workspace ID to retrieve grids for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getCustomData"]} The RTK Query endpoint for retrieving all customData for a given workspace.
     */
    getCustomData: builder.query<CustomData[], { workspaceId: string }>({
        queryFn: async ({ workspaceId }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: [] as CustomData[] };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const customDataSubCollection = collection(workspaceDoc, Collections.CustomData).withConverter(
                customDataConverter,
            );
            const customDataSnapshot = await getDocs(customDataSubCollection);
            const customData: CustomData[] = [];
            customDataSnapshot.forEach(doc => {
                customData.push(doc.data());
            });
            return { data: customData };
        },
        providesTags: [Collections.CustomData],
    }),
    /**
     * A mutation that uploads customer data for a given workspace to the Firestore database.
     */
    uploadCustomData: builder.mutation<undefined, UploadCustomDataRequest & { filename: string }>({
        queryFn: async ({ workspaceId, dataId, filename }) => {
            const uploadCustomerData = httpsCallableFromURL<UploadCustomDataRequest, UploadCustomDataResponse>(
                functions,
                `${apiUrl}/api/workspaces/custom`,
            );

            enqueueSnackbar(`${filename} is being processed. You will be notified when it's done.`, {
                variant: "info",
            });

            uploadCustomerData({
                workspaceId,
                dataId,
            });
            return { data: undefined };
        },
        invalidatesTags: [Collections.CustomData],
    }),
});
