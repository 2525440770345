import { FC } from "react";

import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";

export interface DeleteConfirmationDialogProps {
    title: string;
    message: string;
    onRemove: () => void;
    onCancel: () => void;
    isOpen: boolean;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const DeleteConfirmationDialog: FC<DeleteConfirmationDialogProps> = ({
    isOpen,
    onCancel,
    title,
    message,
    onRemove,
    maxWidth = "sm",
}) => {
    return (
        <div data-testid="delete-dialog">
            <Dialog
                open={isOpen}
                onClose={() => {
                    onCancel();
                }}
                fullWidth
                maxWidth={maxWidth}
            >
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6" component="h2" data-testid="delete-dialog-title">
                                Remove {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="p" data-testid="delete-dialog-message">
                                {message}
                            </Typography>
                        </Grid>
                        <Grid container item xs={12} spacing={1} justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        onCancel();
                                    }}
                                    color="inherit"
                                    data-testid="delete-dialog-cancel-button"
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    onClick={onRemove}
                                    color="error"
                                    data-testid="delete-dialog-remove-button"
                                >
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};
