import { addDoc, collection, deleteDoc, doc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { InvitedUser, SystemGeneratedString } from "honeygrid-types";

import { Collections } from "../collections";
import { invitedUserConverter } from "./converters";
import { Builder } from "./types";

export const invitedUsersEndpoints = (
    builder: Builder,
    apiUrl: string,
    firestore = getFirestore(),
    functions = getFunctions(),
) => ({
    /**
     * A query that retrieves all invited users from the Firestore database for the current workspace.
     * @param workspaceId
     * @returns {ReturnType<typeof createApi>["endpoints"]["getInvitedUsers"]} The RTK Query endpoint for retrieving a list of users.
     */
    getInvitedUsers: builder.query<InvitedUser[], string>({
        queryFn: async workspaceId => {
            const invitedUsersCollection = collection(firestore, Collections.InvitedUsers).withConverter(
                invitedUserConverter,
            );
            const invitedUsersQuery = query(invitedUsersCollection, where("workspaceId", "==", workspaceId));
            const invitedUsersSnapshot = await getDocs(invitedUsersQuery);
            const invitedUsers: InvitedUser[] = [];
            invitedUsersSnapshot.forEach(doc => {
                invitedUsers.push(doc.data());
            });
            return { data: invitedUsers };
        },
        providesTags: [Collections.InvitedUsers],
    }),
    /**
     * A mutation that retrieves creates an invited user in the Firestore database.
     * @param {InvitedUser} invitedUser the invited user object to create
     * @returns {ReturnType<typeof createApi>["endpoints"]["createInvitedUser"]} The RTK Mutation endpoint for creating a new invited user.
     * @invalidates {Collections.InvitedUsers} Invalidates the `Collections.InvitedUsers` tag.
     */
    createInvitedUser: builder.mutation<InvitedUser, InvitedUser>({
        queryFn: async invitedUser => {
            const invitedUsersCollection = collection(firestore, Collections.InvitedUsers).withConverter(
                invitedUserConverter,
            );
            const newInvitedUser = await addDoc(invitedUsersCollection, invitedUser);
            const newInvitedUserId = newInvitedUser.id;
            return { data: { ...invitedUser, id: newInvitedUserId } };
        },
        invalidatesTags: [Collections.InvitedUsers],
    }),
    /**
     * A mutation that updates an invited user in the Firestore database.
     *
     * @param {Partial<InvitedUser>} invitedUser The updated invitedUser object.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateInvitedUser"]} The RTK Mutation endpoint for updating an invited user.
     * @invalidates {Collections.InvitedUsers} Invalidates the `Collections.InvitedUsers` tag.
     * @invalidates {Collections.InvitedUsers, { type: Collections.InvitedUsers, id: invitedUser.id }} Invalidates the specific invited user being updated.
     */
    updateInvitedUser: builder.mutation<Pick<InvitedUser, "id">, Partial<InvitedUser>>({
        queryFn: async invitedUser => {
            const saveInvitedUser = { ...invitedUser };
            const invitedUserDoc = doc(firestore, Collections.InvitedUsers, invitedUser.id ?? "").withConverter(
                invitedUserConverter,
            );
            await updateDoc(invitedUserDoc, saveInvitedUser);
            return { data: { id: invitedUser.id } };
        },
        invalidatesTags: (_result, _error, invitedUser) => [
            Collections.InvitedUsers,
            { type: Collections.InvitedUsers, id: invitedUser.id },
        ],
    }),
    /**
     * A mutation that deletes an invited user from the Firestore database
     *
     * @param {SystemGeneratedString} invitedUserId the ID of the invited user to delete
     * @returns {ReturnType<typeof createApi>["endpoints"]["deleteInvitedUser"]} The RTK Mutation endpoint for deleting an invited user.
     * @invalidates {Collections.InvitedUsers} Invalidates the `Collections.InvitedUsers` tag.
     * @invalidates {Collections.InvitedUsers, { type: Collections.InvitedUsers, id: invitedUserId }} Invalidates the specific invited user being deleted.
     */
    deleteInvitedUser: builder.mutation<Pick<InvitedUser, "id">, SystemGeneratedString>({
        queryFn: async invitedUserId => {
            const invitedUserDoc = doc(firestore, Collections.InvitedUsers, invitedUserId).withConverter(
                invitedUserConverter,
            );
            await deleteDoc(invitedUserDoc);
            return { data: { id: invitedUserId } };
        },
        invalidatesTags: (_result, _error, invitedUserId) => [
            Collections.InvitedUsers,
            { type: Collections.InvitedUsers, id: invitedUserId },
        ],
    }),
    /**
     * Endpoint to call the `inviteUserAndReturnUrl` Cloud Function.
     * @param {Partial<InvitedUser>} invitedUser The invited user object to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["inviteUserAndReturnUrl"]} The RTK Mutation endpoint for creating a new invited user.
     */
    inviteUserAndReturnUrl: builder.mutation<{ url: string }, any>({
        queryFn: async invitedUser => {
            const inviteUserAndReturnUrl = httpsCallableFromURL(functions, `${apiUrl}/api/inviteUserAndReturnUrl`);
            const result = await inviteUserAndReturnUrl(invitedUser);
            return { data: result.data as any };
        },
    }),
});
