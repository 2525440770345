import { FC } from "react";

import { HoneyGridIcon } from "@/icons";
import { Grid, Typography } from "@mui/material";
import { BaseGrid, TargetingType } from "honeygrid-types";

interface TargetingAreasMetricRowProps {
    grid: BaseGrid;
}

export const TargetingAreasMetricRow: FC<TargetingAreasMetricRowProps> = ({ grid }) => {
    const numFilters = Object.keys(grid.targetingFilters)?.length ?? 0;
    const filtersLabel = numFilters === 1 ? "filter" : "filters";

    let targetedAreasLabel = "";

    if (grid.customTargetingAreas?.[TargetingType.COUNTY]?.length) {
        const countyCount = grid.customTargetingAreas[TargetingType.COUNTY].length;
        targetedAreasLabel = `${countyCount} ${countyCount === 1 ? "county" : "counties"}`;
    } else if (grid.customTargetingAreas?.[TargetingType.ZIPCODE]?.length) {
        const zipCount = grid.customTargetingAreas[TargetingType.ZIPCODE].length;
        targetedAreasLabel = `${zipCount} ${zipCount === 1 ? "zip code" : "zip codes"}`;
    } else if (grid.radius) {
        targetedAreasLabel = `${grid.radius} miles`;
    }

    return (
        <Grid item xs={12} display="flex" alignItems="center">
            <HoneyGridIcon fontSize="small" />
            <Typography variant="body2" component="p" sx={{ marginLeft: "8px" }}>
                {targetedAreasLabel} ({`${numFilters} ${filtersLabel}`})
            </Typography>
        </Grid>
    );
};
