import { Global, css } from "@emotion/react";
import type { FC } from "react";
import React, { useMemo } from "react";

import { selectors } from "@/redux/store";
import { themeOptionsMap } from "@/theme";
import { CssBaseline, ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import { useAppSelector } from "@redux/hooks/useAppState";

const GlobalCss = css`
    .firebase-emulator-warning {
        display: none;
    }

    @font-face {
        font-family: "Telegraf";
        font-style: normal;
        font-weight: 300;
        font-display: swap;
        src: url(/fonts/PPTelegraf-Light.woff2) format("woff2");
    }
    @font-face {
        font-family: "Telegraf";
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(/fonts/PPTelegraf-Regular.woff2) format("woff2");
    }
    @font-face {
        font-family: "Telegraf";
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url(/fonts/PPTelegraf-Medium.woff2) format("woff2");
    }
    @font-face {
        font-family: "Telegraf";
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(/fonts/PPTelegraf-Bold.woff2) format("woff2");
    }
`;

export const ThemeProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const themeName = useAppSelector(selectors.theme);
    const theme = useMemo(() => createTheme(themeOptionsMap[themeName as keyof typeof themeOptionsMap]), [themeName]);

    return (
        <MuiThemeProvider theme={theme}>
            <Global styles={GlobalCss} />
            <CssBaseline />
            <>{children}</>
        </MuiThemeProvider>
    );
};
