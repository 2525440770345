import { SystemGeneratedString } from "./utility";

export interface Geometry {
    lat: number;
    lng: number;
}

export interface Location extends BaseLocation {
    id: SystemGeneratedString;
}

type PlaceResult = google.maps.places.PlaceResult;

export interface BaseLocation {
    businessImageUrl?: string;
    name?: PlaceResult["name"];
    address?: PlaceResult["formatted_address"];
    placeId?: PlaceResult["place_id"];
    geometry?: PlaceResult["geometry"];
    website?: PlaceResult["website"];
    phone?: PlaceResult["international_phone_number"];
    openingHours?: PlaceResult["opening_hours"];
    photos?: PlaceResult["photos"];
    rating?: PlaceResult["rating"];
    ratingsTotal?: PlaceResult["user_ratings_total"];
    types?: PlaceResult["types"];
}

export interface PlaceDetailsResponse {
    result: PlaceResult;
    status: google.maps.places.PlacesServiceStatus;
}

export const getPlacesPhotoUrl = (apiUrl: string, photoReference?: string, maxwidth = 400) => {
    if (!photoReference) return;

    const params = new URLSearchParams({
        photoReference,
        maxwidth: maxwidth.toString(),
    });

    return `${apiUrl}/api/places/photos?${params.toString()}`;
};

export const placeResultToLocation = (place: google.maps.places.PlaceResult, apiUrl: string): Location => {
    return {
        name: place.name,
        rating: place.rating,
        ratingsTotal: place.user_ratings_total,
        id: place.place_id ?? "",
        placeId: place.place_id,
        address: place.formatted_address,
        phone: place.international_phone_number,
        types: place.types,
        geometry: place.geometry,
        photos: place.photos,
        businessImageUrl: getPlacesPhotoUrl(apiUrl, (place.photos?.at(0) as any)?.photo_reference),
        openingHours: place.opening_hours,
    };
};
