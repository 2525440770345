import React, { FC, useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { WelcomeModal } from "@/components/WelcomeModal";
import { useEffectOnce } from "@/hooks";
import { EMAIL_STORAGE_KEY, FIRST_NAME_STORAGE_KEY, LAST_NAME_STORAGE_KEY, isGuest } from "@/utils";
import { useAppDispatch } from "@redux/hooks/useAppState";
import {
    useCreateAccountMutation,
    useCreateUserMutation,
    useGetAccountsByUserIdQuery,
} from "@redux/services/firebaseService";
import {
    Unsubscribe,
    User,
    UserCredential,
    getAdditionalUserInfo,
    getAuth,
    isSignInWithEmailLink,
    signInWithEmailLink,
    updateProfile,
} from "firebase/auth";
import { Roles, UserMilestones } from "honeygrid-types";

const AuthLoader: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [dispatch, actions] = useAppDispatch();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [createUser] = useCreateUserMutation();
    const [createAccount] = useCreateAccountMutation();
    const [isWelcomeDialogOpen, setIsWelcomeDialogOpen] = useState(false);
    const auth = getAuth();
    const { data: accounts } = useGetAccountsByUserIdQuery(
        { userId: auth.currentUser?.uid },
        { skip: !auth.currentUser },
    );

    useLayoutEffect(() => {
        // don't allow anonymous users to access the app
        if (location.pathname !== "/" && isGuest()) {
            dispatch(actions.setIsLoginDialogOpen(true));
        } else {
            dispatch(actions.setIsLoginDialogOpen(false));
        }
    });

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            const profile = user?.toJSON() as User;
            dispatch(actions.setUser(profile));
            if (user === null) {
                dispatch(actions.setIsLoginDialogOpen(true));
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, [dispatch, actions, auth]);

    const handleEmailLinkSignIn = async (unsubscribeFn: Unsubscribe) => {
        unsubscribeFn();
        const emailLink = window.location.href;
        if (isSignInWithEmailLink(auth, emailLink)) {
            const url = new URL(emailLink);
            const redirect = url.searchParams.get("redirect");
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            const email = window.localStorage.getItem(EMAIL_STORAGE_KEY);
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again.
                return;
            }

            const postSignInHandler = async (result: UserCredential) => {
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
                setNewUser(result).then(async () => {
                    dispatch(actions.setUser(result.user.toJSON() as User));

                    // Navigate to previous screen
                    if (redirect) {
                        navigate(redirect);
                    }
                });
            };

            signInWithEmailLink(auth, email, emailLink).then(postSignInHandler);
        }
    };

    /**
     * We use this to ensure the Auth state is available before proceeding
     * with the login/sign up completion flow
     */
    useEffectOnce(() => {
        const unsubscribe = auth.onAuthStateChanged(_ => {
            handleEmailLinkSignIn(unsubscribe);
        });
    });

    const setNewUser = async (userCredential: UserCredential) => {
        const additionalInfo = getAdditionalUserInfo(userCredential);

        if (additionalInfo?.isNewUser || userCredential.operationType === "link") {
            const firstName = window.localStorage.getItem(FIRST_NAME_STORAGE_KEY);
            const lastName = window.localStorage.getItem(LAST_NAME_STORAGE_KEY);
            if (!firstName || !lastName) {
                // TODO handle this edge case where we don't have first or last name.
                // we will probably want to have a popup asking for their information.
                return;
            }

            // update user profile
            const createUserPromise = createUser({
                userId: auth.currentUser?.uid ?? undefined,
                user: {
                    email: window.localStorage.getItem(EMAIL_STORAGE_KEY) ?? "",
                    firstName: window.localStorage.getItem(FIRST_NAME_STORAGE_KEY) ?? "",
                    lastName: window.localStorage.getItem(LAST_NAME_STORAGE_KEY) ?? "",
                    createdDate: new Date().toISOString(),
                    milestones: [UserMilestones.HAS_SEEN_GRID_WELCOME_SCREEN],
                },
            }).catch(err => {
                console.error("Unable to create user profile", err);
            });

            // update auth profile
            const updateProfilePromise = updateProfile(userCredential.user, {
                displayName: `${firstName} ${lastName}`,
            }).catch(err => {
                console.error("Unable to update auth user profile", err);
            });

            if (auth.currentUser?.uid && !accounts) {
                // create account
                await createAccount({
                    name: "default",
                    subscription: "free", // all accounts start as free
                    active: true,
                    locations: 1, // assuming they've reached this code by creating one location
                    roles: {
                        [Roles.Owner]: [auth.currentUser.uid],
                        [Roles.Editor]: [] as string[],
                        [Roles.Viewer]: [] as string[],
                    },
                });
            }

            // call both at the same time to be more efficient
            Promise.all([createUserPromise, updateProfilePromise]).finally(() => {
                // Clear user data from local storage
                window.localStorage.removeItem(EMAIL_STORAGE_KEY);
                window.localStorage.removeItem(FIRST_NAME_STORAGE_KEY);
                window.localStorage.removeItem(LAST_NAME_STORAGE_KEY);
            });
            setIsWelcomeDialogOpen(true);
        }
        dispatch(actions.setIsProfileButtonShown(true));
    };
    return (
        <>
            {!loading && children}
            <WelcomeModal isOpen={isWelcomeDialogOpen} onClose={() => setIsWelcomeDialogOpen(false)} />
        </>
    );
};

export { AuthLoader };
