import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Grid, LayerState, LayerType, MapTool, ReferenceFields } from "honeygrid-types";

import { authSlice } from "../auth/authSlice";
import { FeatureNames } from "../names";

export interface MapState {
    layers: LayerState;
    selectedTool: MapTool | null;
    /**
     * This is a list of cell ids that are currently selected by the user via the stats table.
     */
    selectedCells: string[];
    selectedReferenceField: ReferenceFields | string | null;
    selectedGrid?: Grid["id"]; // Selected grid state
}

const initialState: MapState = {
    layers: {
        zipcodes: false,
        counties: false,
    },
    selectedTool: null,
    selectedCells: [],
    selectedReferenceField: null,
    selectedGrid: undefined,
};

export const mapSlice = createSlice({
    name: FeatureNames.Map,
    initialState,
    reducers: {
        toggleLayerVisibility: (state, action: PayloadAction<LayerType>) => {
            const layer = action.payload;
            state.layers[layer.id] = !state.layers[layer.id];
        },
        toggleTool: (state, action: PayloadAction<MapTool>) => {
            state.selectedTool = state.selectedTool === action.payload ? null : action.payload;
        },
        setSelectedCells: (state, action: PayloadAction<string[]>) => {
            state.selectedCells = action.payload;
        },
        setSelectedGrid: (state, action: PayloadAction<string>) => {
            state.selectedGrid = action.payload;
        },
        setSelectedReferenceField: (state, action: PayloadAction<MapState["selectedReferenceField"]>) => {
            state.selectedReferenceField = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(authSlice.actions.logOut, () => initialState);
    },
});

export type MapActions = typeof mapSlice.actions;
