import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

import { FeatureNames } from "../names";

export interface LocalMapViewState {
    bearing: number;
    latitude: number;
    longitude: number;
    pitch: number;
    zoom: number;
}

interface Coordinates {
    lat: number;
    lng: number;
}

export interface MapViewBounds {
    northEast: Coordinates;
    southWest: Coordinates;
}

export interface ModalState {
    isComingSoonDialogOpen: boolean;
    isInviteUsersDialogOpen: boolean;
    isWorkspaceSelectorOpen: boolean;
    isWorkspaceEditorOpen: boolean;
    isSyncToGoogleDialogOpen: boolean;
}

export interface HeaderState {
    isProfileButtonShown: boolean;
}

export interface UIState {
    modalState: ModalState;
    headerState: HeaderState;
    mapViewState: LocalMapViewState;
    mapViewBounds?: MapViewBounds;
}

const initialState: UIState = {
    modalState: {
        isComingSoonDialogOpen: false,
        isInviteUsersDialogOpen: false,
        isWorkspaceSelectorOpen: false,
        isWorkspaceEditorOpen: false,
        isSyncToGoogleDialogOpen: false,
    },
    headerState: {
        isProfileButtonShown: true,
    },
    mapViewState: {
        bearing: -17.6,
        latitude: 30.3060973,
        longitude: -97.9577071,
        pitch: 45,
        zoom: 8,
    },
};

export const uiSlice = createSlice({
    name: FeatureNames.UI,
    initialState,
    reducers: {
        toggleIsComingSoonDialogOpen: state => {
            state.modalState.isComingSoonDialogOpen = !state.modalState.isComingSoonDialogOpen;
        },
        toggleIsInviteUsersDialogOpen: state => {
            state.modalState.isInviteUsersDialogOpen = !state.modalState.isInviteUsersDialogOpen;
        },
        changeMapViewState: (state, { payload }: PayloadAction<Partial<LocalMapViewState>>) => {
            state.mapViewState = {
                ...state.mapViewState,
                ...payload,
            };
        },
        setMapViewBounds: (state, { payload }: PayloadAction<MapViewBounds>) => {
            state.mapViewBounds = payload;
        },
        setIsWorkspaceSelectorOpen: (state, { payload }: PayloadAction<boolean>) => {
            state.modalState.isWorkspaceSelectorOpen = payload;
        },
        setIsWorkspaceEditorOpen: (state, { payload }: PayloadAction<boolean>) => {
            state.modalState.isWorkspaceEditorOpen = payload;
        },
        setIsProfileButtonShown: (state, { payload }: PayloadAction<boolean>) => {
            state.headerState.isProfileButtonShown = payload;
        },
        setIsSyncToGoogleDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
            state.modalState.isSyncToGoogleDialogOpen = payload;
        },
    },
});

export type UIActions = typeof uiSlice.actions;
