import { EXCLUSION_TOOL, INCLUSION_TOOL, ReferenceFields } from "honeygrid-types";

import { RootState } from "../../initStore";

/**
 * Select whether the zipcode layer is enabled
 */
export const zipCodeMapLayer = (state: RootState) => state.map.layers.zipcodes;

/**
 * Select whether the county layer is enabled
 */
export const countyMapLayer = (state: RootState) => state.map.layers.counties;

/**
 * Select the map layers
 */
export const mapLayers = (state: RootState) => state.map.layers;
/**
 * Select whether the inclusion tool is enabled
 */
export const inclusionToolEnabled = (state: RootState) => state.map.selectedTool === INCLUSION_TOOL;
/**
 * Select whether the exclusion tool is enabled
 */
export const exclusionToolEnabled = (state: RootState) => state.map.selectedTool === EXCLUSION_TOOL;
/**
 * Select the selected tool
 */
export const selectedTool = (state: RootState) => state.map.selectedTool;
/**
 * Boolean indicating whether a tool is currently enabled
 */
export const isToolEnabled = (state: RootState) => state.map.selectedTool !== null;
/**
 * Select the selected cells
 */
export const selectedCells = (state: RootState) => state.map.selectedCells;
/**
 * Selected reference field currently shown on the map
 */
export const selectedReferenceField = (state: RootState) =>
    (state.map.selectedReferenceField ?? ReferenceFields.Households) as ReferenceFields;
/**
 * Currently selected grid id
 */
export const selectedGridId = (state: RootState) => state.map.selectedGrid;
