import { FC } from "react";

import { Home } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { BaseGrid } from "honeygrid-types";

interface TargetingCoverageMetricRowProps {
    grid: BaseGrid;
}

export const TargetingCoverageMetricRow: FC<TargetingCoverageMetricRowProps> = ({ grid }) => {
    return (
        <>
            {grid.targetingCoverage ? (
                <Grid item xs={12} display="flex" alignItems="center">
                    <Home fontSize="small" />
                    <Typography variant="body2" component="p" sx={{ marginLeft: "8px" }}>
                        {`${(grid?.targetedHouseholds ?? 0).toLocaleString("en-US", {
                            notation: "compact",
                            compactDisplay: "short",
                        })}/${(grid?.households ?? 0).toLocaleString("en-US", {
                            notation: "compact",
                            compactDisplay: "short",
                        })}`}{" "}
                        households (
                        {(grid.targetingCoverage / 1000).toLocaleString("en-US", {
                            style: "percent",
                            maximumFractionDigits: 1,
                        })}{" "}
                        )
                    </Typography>
                </Grid>
            ) : null}
        </>
    );
};
