import { FC, useCallback, useState } from "react";

import { Dialog, Grid } from "@mui/material";

import { SignInForm } from "../SignInForm";
import { SignUpForm } from "../SignUpForm";

interface AuthDialogProps {
    open: boolean;
}

/**
 * Modal for showing sign in and sign up forms
 *
 * @param open
 * @param onClose
 */
export const AuthDialog: FC<AuthDialogProps> = ({ open }) => {
    const [signInVisible, toggleAuthForm] = useState(true);
    const handleToggle = useCallback(() => {
        toggleAuthForm(oldState => !oldState);
    }, []);

    return (
        <Dialog open={open} fullWidth maxWidth={"xs"}>
            <Grid container p={2}>
                {signInVisible ? (
                    <SignInForm toggleView={handleToggle} />
                ) : (
                    <SignUpForm onSignInClicked={handleToggle} />
                )}
            </Grid>
        </Dialog>
    );
};
