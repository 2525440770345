export enum Collections {
    Accounts = "accounts",
    Campaigns = "campaigns",
    Grids = "grids",
    Locations = "locations",
    Marketing = "marketing",
    Places = "google.placeResults",
    Users = "users",
    Workspaces = "workspaces",
    PaymentLinks = "paymentLinks",
    InvitedUsers = "invitedUsers",
    CustomData = "customData",
    Cells = "cells",
    DataSources = "dataSources",
    Connections = "connections",
}
