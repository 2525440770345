import { FC, useCallback, useMemo, useState } from "react";

import { Logo } from "@/components/Logo";
import { EVENT_TYPES, logEvent } from "@/utils/analytics";
import {
    Button,
    Checkbox,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Link,
    TextField,
    Typography,
} from "@mui/material";

import { ErrorNotification } from "../ErrorNotification";
import { MagicLinkView } from "../MagicLinkView";
import { handleSignInWithoutPassword } from "../handlers";
import { emailValidator } from "../validators";

interface SignInFormProps {
    toggleView: () => void;
    onClose?: () => void;
    redirectUrl?: string;
}

/**
 * Handles collection of user's email for login
 * @param toggleView
 */
export const SignInForm: FC<SignInFormProps> = ({ toggleView, redirectUrl = location.pathname }) => {
    const [email, setEmail] = useState(localStorage.getItem("SIGN_IN_EMAIL") || "");
    const isValid = useMemo(() => emailValidator.safeParse(email).success, [email]);
    const [busy, setBusy] = useState(false);
    const [processError, setError] = useState("");
    const [hasSignedIn, setHasSignedIn] = useState(false);
    const [isRememberMeChecked, setIsRememberMeChecked] = useState(true);

    const onContinueClicked = useCallback(async () => {
        if (isValid && !busy) {
            try {
                setBusy(true);
                if (isRememberMeChecked) {
                    localStorage.setItem("SIGN_IN_EMAIL", email);
                }
                await handleSignInWithoutPassword(email, redirectUrl);
                logEvent(EVENT_TYPES.USER_LOGIN, { method: "magic-link" });
                setHasSignedIn(true);
            } catch (error) {
                console.log("error", error);
                setError((error as Error).message);
            } finally {
                setBusy(false);
            }
        } else if (!isValid) {
            setError("Please enter a valid email address");
        }
    }, [isValid, busy, isRememberMeChecked, email, redirectUrl]);

    return hasSignedIn ? (
        <MagicLinkView resend={onContinueClicked} email={email} />
    ) : (
        <Grid minWidth={400}>
            <DialogTitle>
                <Grid container flexDirection={"column"} alignItems={"center"}>
                    <Grid item>
                        <Logo />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">Precision marketing made simpler</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container flexDirection={"column"} rowSpacing={4}>
                    <Grid item mt={2}>
                        <TextField
                            label="Email"
                            placeholder="Your email"
                            variant="outlined"
                            fullWidth
                            value={email}
                            disabled={busy}
                            error={!isValid && email !== ""}
                            onKeyDown={({ key }) => key === "Enter" && onContinueClicked()}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid container flexDirection={"column"} mt={4} alignItems={"center"}>
                        <Grid item width={"100%"}>
                            <Button
                                fullWidth
                                onClick={onContinueClicked}
                                variant="contained"
                                type="submit"
                                role="login"
                            >
                                Sign in
                            </Button>
                        </Grid>
                        <Grid item mt={5}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isRememberMeChecked}
                                            onChange={() => setIsRememberMeChecked(!isRememberMeChecked)}
                                        />
                                    }
                                    label="Remember me?"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent={"center"}>
                        <Grid item>
                            <Typography variant="body2">
                                No account?{" "}
                                <Link
                                    role="create-account"
                                    onClick={() => !busy && toggleView()}
                                    color="primary"
                                    sx={{ fontWeight: "bold", cursor: "pointer", ml: 1 }}
                                >
                                    Create one
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    <ErrorNotification error={processError} clearError={() => setError("")} />
                </Grid>
            </DialogContent>
        </Grid>
    );
};
