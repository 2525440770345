import type { FC } from "react";
import React, { useEffect, useState } from "react";

import { Loader } from "@googlemaps/js-api-loader";

export const PlacesLoader: FC<{ children: React.ReactNode }> = ({ children }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) return;

        const loader = new Loader({
            apiKey: import.meta.env.VITE_GOOGLE_PLACES_API_KEY,
            version: "beta",
        });

        loader.importLibrary("places").then(() => {
            setLoaded(true);
        });
    }, [loaded]);

    if (!loaded) {
        return null;
    }

    return <>{children}</>;
};
