export interface DataSource {
    id: string;
    name: string;
    type: string;
    dataId?: string;
    createdAt: string;
    createdBy: string;
    status: (typeof DataSourceStatus)[keyof typeof DataSourceStatus];
    summaries?: Record<string, Record<string, number>>;
}

export const DataSourceStatus = {
    Uploaded: "uploaded",
    Processing: "processing",
    Ready: "ready",
    Error: "error",
    Created: "created",
} as const;
