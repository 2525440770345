import { AggregatedDemographics, HoneyGridFeatureCollection, ReferenceFields } from "honeygrid-types";

export const calculateHoneygridStats = (geoJson: HoneyGridFeatureCollection, customDataKeys: string[]) => {
    const aggregatedDemographics: AggregatedDemographics = {
        averages: {},
        sortedCellIds: {},
        cells: {},
    };

    for (const feature of geoJson.features) {
        aggregatedDemographics.cells[feature.properties.id] = feature.properties;
    }

    for (const referenceField of [...Object.values(ReferenceFields), ...customDataKeys]) {
        aggregatedDemographics.sortedCellIds[referenceField] = [];
        const sortCopy = [...geoJson.features];
        sortCopy.sort((a, b) => {
            return (b.properties[referenceField] ?? 0) - (a.properties[referenceField] ?? 0);
        });

        sortCopy.forEach((feature, index) => {
            const featureCopy = structuredClone(feature);
            if (
                featureCopy.properties[referenceField] === undefined ||
                featureCopy.properties[referenceField] === null ||
                featureCopy.properties[referenceField] === 0
            ) {
                delete featureCopy.properties[referenceField];
            } else {
                featureCopy.properties = {
                    ...featureCopy.properties,
                    [`${referenceField}Index`]: index,
                };
                aggregatedDemographics.sortedCellIds[referenceField].push(featureCopy.properties?.id ?? "");
            }
        });

        const {
            // max,
            total,
            count,
        } = sortCopy.reduce(
            (acc, feature) => {
                const value = feature.properties[referenceField];
                if (!value) return acc;

                return {
                    max: Math.max(acc.max, value),
                    total: acc.total + value,
                    count: acc.count + 1,
                };
            },
            { max: 0, total: 0, count: 0 } as { max: number; total: number; count: number },
        );

        aggregatedDemographics.averages[referenceField] = total / count;
    }

    return aggregatedDemographics;
};
