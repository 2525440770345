import { FC, PropsWithChildren, ReactElement } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { AuthLoader, PlacesLoader } from "@/loaders";
import { store as defaultStore } from "@redux/store";
import { SnackbarProvider } from "notistack";

import { ThemeProvider } from "./Theme";

interface PropsWithStore extends PropsWithChildren {
    store?: typeof defaultStore;
}

export const AppProvider: FC<PropsWithStore> = ({ children, store = defaultStore }) => {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AuthLoader>
                    <PlacesLoader>
                        <ThemeProvider>
                            <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
                        </ThemeProvider>
                    </PlacesLoader>
                </AuthLoader>
            </BrowserRouter>
        </Provider>
    ) as ReactElement<any, any>;
};
