import { getAuth } from "firebase/auth";

import { isAuthorized } from ".";

/**
 * Wraps fetch and adds the auth bearer token to the request headers
 * @param url
 * @param request
 * @returns
 */
export const fetchWithAuth = async (url: URL | RequestInfo, request?: RequestInit | undefined): Promise<Response> => {
    try {
        const authToken = await getAuth().currentUser?.getIdToken();

        // Check if the token is available
        if (!authToken) {
            throw new Error("No auth token available");
        }

        request = {
            ...request,
            credentials: "include",
            headers: {
                ...request?.headers,
                "Authorization": `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(url, request);

        isAuthorized(response);

        return response;
    } catch (e) {
        console.error("Error fetching with auth", e);
        return Promise.reject(new Error("Error fetching with auth"));
    }
};
