import React from "react";
import ReactDOM from "react-dom/client";

import { App } from "@/components/App";

import { AppProvider } from "./providers";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <AppProvider>
            <App />
        </AppProvider>
    </React.StrictMode>,
);
