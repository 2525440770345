import type { EffectCallback } from "react";
import { useEffect, useRef } from "react";

export const useEffectOnce = (effect: EffectCallback) => {
    const didRun = useRef(false);
    const effectRef = useRef(effect);

    useEffect(() => {
        if (didRun.current) {
            return;
        }

        didRun.current = true;
        return effectRef.current();
    }, []);
};
