import { fetchWithAuth } from "@/utils/fetchWithAuth";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { Connection } from "honeygrid-types";

import { Collections } from "../collections";
import { Builder } from "./types";

export const connectionsEndpoints = (
    builder: Builder,
    _apiUrl?: string,
    _firestore = getFirestore(),
    auth = getAuth(),
) => ({
    /**
     * A query that retrieves all connections for a given workspace from the Firestore database.
     * @param {{ workspaceId: string }} params The workspace ID to retrieve grids for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getConnections"]} The RTK Query endpoint for retrieving all connections for a given workspace.
     */
    getConnections: builder.query<Connection[], { workspaceId: string }>({
        queryFn: async ({ workspaceId }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: [] as Connection[] };
            }

            if (!auth.currentUser) {
                console.log("No user is authenticated");
                return { data: [] as Connection[] };
            }

            const response = await fetchWithAuth(
                `${import.meta.env.VITE_CF_API_URL}/firebase/connections?workspaceId=${workspaceId}`,
            );
            const connections = (await response.json()) as Connection[];
            return { data: connections };
        },
        providesTags: [Collections.Connections],
    }),
    /**
     * A query that retrieves a single connection from the Firestore database by its ID and workspace ID.
     *
     * @param {{ workspaceId: string; connectionId: string }} params The workspace ID and grid ID to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getGridById"]} The RTK Query endpoint for retrieving a single connection by ID and workspace ID.
     */
    getConnectionById: builder.query<Connection, { workspaceId: string; connectionId: string }>({
        queryFn: async ({ workspaceId, connectionId }) => {
            if (!workspaceId || !connectionId) {
                console.log(
                    `Missing either workspaceId or connectionId. workspaceId: ${workspaceId}. connectionId: ${connectionId}`,
                );

                return { data: {} as Connection };
            }
            const response = await fetchWithAuth(
                `${import.meta.env.VITE_CF_API_URL}/firebase/connections/${connectionId}`,
            );
            const connection = (await response.json()) as Connection;
            return { data: connection };
        },
        providesTags: (_result, _error, { connectionId }) => [{ type: Collections.Connections, connectionId }],
    }),

    /**
     * A query that archives a connection for a given workspace from the Firestore database.
     * @param workspaceId: string The workspace ID that owns this connection.
     * @param connectionId: string The connection ID to archive.
     * @returns {ReturnType<typeof createApi>["endpoints"]["archiveConnection"]} The RTK Query endpoint for deleting a connection in a given workspace.
     */
    deleteConnection: builder.mutation<string, { workspaceId: string; connectionId: Connection["id"] }>({
        queryFn: async ({ connectionId }) => {
            try {
                await fetchWithAuth(`${import.meta.env.VITE_CF_API_URL}/firebase/connections/${connectionId}`, {
                    method: "DELETE",
                });
                return { data: "success" };
            } catch (e) {
                if (import.meta.env.MODE === "development") {
                    console.error(e);
                }
                return { error: e };
            }
        },
        invalidatesTags: [Collections.Connections],
    }),
    /**
     * A mutation that updates a connection for a given workspace from the Firestore database.
     * @param workspaceId: string The workspace ID that owns this connection.
     * @param connectionId: string The connection ID to update.
     * @param connection: Partial<Connection> The connection object to update.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateConnection"]} The RTK Query endpoint for updating a connection in a given workspace.
     */
    updateConnection: builder.mutation<
        Connection,
        { workspaceId: string; connectionId: Connection["id"]; connection: Partial<Connection> }
    >({
        queryFn: async ({ connectionId, connection }) => {
            try {
                // const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
                // const connectionDoc = doc(workspaceDoc, Collections.Connections, connectionId);
                // await updateDoc(connectionDoc, connection);
                const response = await fetchWithAuth(
                    `${import.meta.env.VITE_CF_API_URL}/firebase/connections/${connectionId}`,
                    {
                        method: "PUT",
                        body: JSON.stringify(connection),
                    },
                );
                const updatedConnection = (await response.json()) as Connection;
                return { data: updatedConnection };
            } catch (e) {
                if (import.meta.env.MODE === "development") {
                    console.error(e);
                }
                return { error: e };
            }
        },
        invalidatesTags: [Collections.Connections],
    }),
});
