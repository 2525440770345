import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Routes as NamedRoutes } from "@/routes";
import { Grid, Paper } from "@mui/material";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import HomePageBackground from "../../assets/images/HomePageBackground.png";
import { SignInForm } from "../Auth/SignInForm";
import { SignUpForm } from "../Auth/SignUpForm";

export const SignUpWindow: FC = () => {
    const [searchParams] = useSearchParams();
    const workspaceId = searchParams.get("workspaceId");
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, user => {
            if (user) {
                if (workspaceId) {
                    window.location.href = `/${workspaceId}/honeygrid`;
                } else {
                    window.location.href = "/";
                }
            }
        });
    }, [workspaceId]);

    return (
        <>
            <Grid
                container
                sx={{
                    backgroundImage: `url(${HomePageBackground})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    minHeight: "100vh",
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    left: 0,
                }}
                justifyContent="center"
                alignItems={"center"}
                data-testid="sign-up-window"
            >
                <Paper elevation={0}>
                    {isLogin ? (
                        <SignInForm
                            toggleView={() => setIsLogin(false)}
                            redirectUrl={workspaceId ? `${workspaceId}/honeygrid` : NamedRoutes.Dashboard}
                        />
                    ) : (
                        <SignUpForm
                            onSignInClicked={() => setIsLogin(true)}
                            redirectUrl={workspaceId ? `${workspaceId}/honeygrid` : NamedRoutes.Dashboard}
                        />
                    )}
                </Paper>
            </Grid>
        </>
    );
};
