import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BaseGrid, MapTool, ReferenceFields, generateBaseGrid } from "honeygrid-types";

import { FeatureNames } from "../names";

// middleware that watched for new data in getGridById
// set the grid state to the new data

export interface GridState {
    grid: BaseGrid | null; // Draft grid state
    targetingFilters: BaseGrid["targetingFilters"] | null;
}

const initialState: () => GridState = () => {
    const grid = generateBaseGrid({ empty: true });
    return { grid, targetingFilters: grid.targetingFilters };
};

export const gridSlice = createSlice({
    name: FeatureNames.Grid,
    initialState,
    reducers: {
        resetGrid: state => {
            const grid = generateBaseGrid({ empty: true });
            state.grid = grid;
            state.targetingFilters = grid.targetingFilters;
        },
        setGrid: (state, action: PayloadAction<BaseGrid>) => {
            state.grid = action.payload;
            state.targetingFilters = action.payload.targetingFilters;
        },
        setTargetingRules: (state, action: PayloadAction<BaseGrid["targetingFilters"]>) => {
            state.targetingFilters = action.payload;
        },
        removeTargetingFilter: (state, action: PayloadAction<string>) => {
            const referenceField = action.payload as ReferenceFields;
            if (state.targetingFilters) {
                delete state.targetingFilters[referenceField];
            }
            if (state.grid?.targetingFilters) {
                delete state.grid.targetingFilters[referenceField];
            }
        },
        updateTargetingFilter: (state, action: PayloadAction<{ id: string; percentage: number }>) => {
            if (state.targetingFilters) {
                const referenceField = action.payload.id as ReferenceFields;
                state.targetingFilters[referenceField] = { percentage: action.payload.percentage };
            }
        },
        setTargetingFilters: (state, action: PayloadAction<BaseGrid["targetingFilters"]>) => {
            state.targetingFilters = action.payload;
        },
        updateGridFilter: (state, action: PayloadAction<string>) => {
            if (state.grid && state.targetingFilters) {
                const referenceField = action.payload as ReferenceFields;
                if (!state.grid.targetingFilters[referenceField]) {
                    state.grid.targetingFilters[referenceField] = { percentage: 0 };
                }
                state.grid.targetingFilters[referenceField].percentage =
                    state.targetingFilters[referenceField].percentage;
            }
        },
        setTargetingType: (state, action: PayloadAction<GridState["grid"]>) => {
            if (!state.grid || !action.payload) return;

            state.grid.targetingType = action.payload.targetingType;
            return state;
        },
        handleManualFilterChange: (state, action: PayloadAction<{ cellId: string; selectedTool: MapTool }>) => {
            if (!state.grid) return;
            if (!state.grid.manualFilters) state.grid.manualFilters = {};
            const { cellId: id, selectedTool } = action.payload;
            if (selectedTool === "inclusion") {
                if (!state.grid.manualFilters.inclusion) state.grid.manualFilters.inclusion = [];
                if (!state.grid.manualFilters.inclusion.includes(id)) {
                    state.grid.manualFilters.inclusion.push(id);
                    // Remove id from exclusion filter if it exists
                    state.grid.manualFilters.exclusion = state.grid.manualFilters.exclusion?.filter(
                        cell => cell !== id,
                    );
                } else {
                    state.grid.manualFilters.inclusion = state.grid.manualFilters.inclusion.filter(cell => cell !== id);
                }
            } else if (selectedTool === "exclusion") {
                if (!state.grid.manualFilters.exclusion) state.grid.manualFilters.exclusion = [];
                if (!state.grid.manualFilters.exclusion.includes(id)) {
                    state.grid.manualFilters.exclusion.push(id);
                    // Remove id from inclusion filter if it exists
                    state.grid.manualFilters.inclusion = state.grid.manualFilters.inclusion?.filter(
                        cell => cell !== id,
                    );
                } else {
                    state.grid.manualFilters.exclusion = state.grid.manualFilters.exclusion.filter(cell => cell !== id);
                }
            }
        },
        handleBulkManualFilterChange: (state, action: PayloadAction<{ cellIds: string[]; selectedTool: MapTool }>) => {
            if (!state.grid) return;
            if (!state.grid.manualFilters) state.grid.manualFilters = {};
            const { cellIds: ids, selectedTool } = action.payload;
            if (selectedTool === "inclusion") {
                if (!state.grid.manualFilters.inclusion) state.grid.manualFilters.inclusion = [];
                state.grid.manualFilters.inclusion = [...new Set(state.grid.manualFilters.inclusion.concat(ids))];
                // Remove ids from exclusion filter if they exist
                if (state.grid.manualFilters.exclusion) {
                    state.grid.manualFilters.exclusion = state.grid.manualFilters.exclusion.filter(
                        cell => !ids.includes(cell),
                    );
                }
            } else if (selectedTool === "exclusion") {
                if (!state.grid.manualFilters.exclusion) state.grid.manualFilters.exclusion = [];
                state.grid.manualFilters.exclusion = [...new Set(state.grid.manualFilters.exclusion.concat(ids))];
                // Remove ids from inclusion filter if they exist
                if (state.grid.manualFilters.inclusion) {
                    state.grid.manualFilters.inclusion = state.grid.manualFilters.inclusion.filter(
                        cell => !ids.includes(cell),
                    );
                }
            }
        },
        handleBulkManualFilterClear: (state, action: PayloadAction<{ cellIds: string[] }>) => {
            if (!state.grid) return;
            if (!state.grid.manualFilters) state.grid.manualFilters = {};
            const { cellIds: ids } = action.payload;
            if (state.grid.manualFilters.inclusion) {
                state.grid.manualFilters.inclusion = state.grid.manualFilters.inclusion.filter(
                    cell => !ids.includes(cell),
                );
            }
            if (state.grid.manualFilters.exclusion) {
                state.grid.manualFilters.exclusion = state.grid.manualFilters.exclusion.filter(
                    cell => !ids.includes(cell),
                );
            }
        },
        handleManualFilterReset: (state, action: PayloadAction<{ selectedTool: MapTool }>) => {
            if (!state.grid) return;
            if (!state.grid.manualFilters) state.grid.manualFilters = {};
            const { selectedTool } = action.payload;
            if (selectedTool === "inclusion") {
                state.grid.manualFilters.inclusion = [];
            } else if (selectedTool === "exclusion") {
                state.grid.manualFilters.exclusion = [];
            }
        },
        setTargetingMetrics: (
            state,
            action: PayloadAction<{ households: number; coverage: number; targetedHouseholds: number }>,
        ) => {
            if (!state.grid) return;
            const { households, coverage, targetedHouseholds } = action.payload;
            state.grid.households = households;
            state.grid.targetingCoverage = coverage;
            state.grid.targetedHouseholds = targetedHouseholds;
        },
    },
});

export type GridSlice = typeof gridSlice.actions;
