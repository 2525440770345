import { createSvgIcon } from "@mui/material";


export const HoneygridMarkerIcon = createSvgIcon(
    <>
        <g filter="url(#filter0_ddd_1011_44612)">
            <path
                d="M13.853 20.578c-0.722 1.249 -2.526 1.249 -3.248 0l-2.537 -4.395c-0.722 -1.249 0.18 -2.813 1.624 -2.813h5.075c1.444 0 2.345 1.563 1.624 2.813l-2.537 4.395Z"
                fill="white"
            />
        </g>
        <defs>
            <filter
                id="filter0_ddd_1011_44612"
                x="0.0195312"
                y="0.828125"
                width="47.7734"
                height="46.8594"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="9" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1011_44612" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="6" />
                <feGaussianBlur stdDeviation="5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                <feBlend mode="normal" in2="effect1_dropShadow_1011_44612" result="effect2_dropShadow_1011_44612" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_1011_44612" />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="2.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="effect2_dropShadow_1011_44612" result="effect3_dropShadow_1011_44612" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1011_44612" result="shape" />
            </filter>
        </defs>
        <circle cx="12.05" cy="11" r="6" fill="currentColor" stroke="white" strokeWidth="3" />
    </>,
    "HoneygridMarker",
);