import { getAuth } from "firebase/auth";
import {
    FieldPath,
    addDoc,
    and,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    or,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { EntityStatus, Workspace } from "honeygrid-types";

import { Collections } from "../collections";
import { workspaceConverter } from "./converters";
import { Builder } from "./types";

export const workspacesEndpoints = (builder: Builder, firestore = getFirestore(), auth = getAuth()) => ({
    /**
     * A query that retrieves all workspaces for the current user from the Firestore database.
     *
     * @param void
     * @returns {ReturnType<typeof createApi>["endpoints"]["getWorkspaces"]} The RTK Query endpoint for retrieving all workspaces for the current user.
     */
    getWorkspaces: builder.query<Workspace[], void>({
        queryFn: async () => {
            try {
                if (!auth.currentUser) {
                    return { data: [] as Workspace[] };
                }

                const workspacesCollection = collection(firestore, Collections.Workspaces).withConverter(
                    workspaceConverter,
                );
                const workspacesQuery = query(
                    workspacesCollection,
                    and(
                        or(
                            where(new FieldPath("roles", "owner"), "array-contains", auth.currentUser.uid),
                            where(new FieldPath("roles", "editor"), "array-contains", auth.currentUser.uid),
                            where(new FieldPath("roles", "viewer"), "array-contains", auth.currentUser.uid),
                        ),
                        where("status", "!=", EntityStatus.ARCHIVED),
                    ),
                );
                const snapshot = await getDocs(workspacesQuery);
                const data = snapshot.docs.map(workspace => workspace.data());

                return { data };
            } catch (error) {
                console.error(error);
                return { data: [] as Workspace[] };
            }
        },
        providesTags: [Collections.Workspaces],
    }),
    /**
     * A query that retrieves a single workspace from the Firestore database by its ID.
     *
     * @param {string} id The ID of the workspace to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getWorkspaceById"]} The RTK Query endpoint for retrieving a single workspace by ID.
     */
    getWorkspaceById: builder.query<Workspace, string>({
        queryFn: async id => {
            const workspaceDoc = doc(firestore, Collections.Workspaces, id).withConverter(workspaceConverter);
            const workspace = await getDoc(workspaceDoc);
            return { data: workspace.data() as Workspace };
        },
        providesTags: (_result, _error, id) => [{ type: Collections.Workspaces, id }],
    }),
    /**
     * A mutation that creates a new workspace in the Firestore database.
     *
     * @param {Partial<Workspace>} workspace The workspace object to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createWorkspace"]} The RTK Mutation endpoint for creating a new workspace.
     * @invalidates {Collections.Workspaces} Invalidates the `Collections.Workspaces` tag.
     */
    createWorkspace: builder.mutation<Workspace, Partial<Workspace>>({
        queryFn: async workspace => {
            const saveWorkspace = { ...workspace };
            const workspacesCollection = collection(firestore, Collections.Workspaces).withConverter(
                workspaceConverter,
            );
            const docRef = await addDoc(workspacesCollection, saveWorkspace);
            const newWorkspace = { ...workspace, id: docRef.id } as Workspace;
            return { data: newWorkspace };
        },
        invalidatesTags: [Collections.Workspaces],
    }),
    /**
     * A mutation that updates a workspace in the Firestore database.
     *
     * @param {Partial<Workspace>} workspace The updated workspace object.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateWorkspace"]} The RTK Mutation endpoint for updating a workspace.
     * @invalidates {Collections.Workspaces} Invalidates the `Collections.Workspaces` tag.
     * @invalidates {Collections.Workspaces, { type: Collections.Workspaces, id: workspace.id }} Invalidates the specific workspace being updated.
     */
    updateWorkspace: builder.mutation<Pick<Workspace, "id">, Partial<Workspace>>({
        queryFn: async workspace => {
            const saveWorkspace = { ...workspace };
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspace.id ?? "").withConverter(
                workspaceConverter,
            );
            // TODO COME FIX AFTER REMOVING ENUM TYPE
            await updateDoc(workspaceDoc, saveWorkspace as any);
            return { data: { id: workspace.id ?? "" } };
        },
        invalidatesTags: (_result, _error, workspace) => [
            Collections.Workspaces,
            { type: Collections.Workspaces, id: workspace.id },
        ],
    }),
    /**
     * A mutation that archives a workspace in the Firestore database.
     *
     * @param {string} id The ID of the workspace to delete.
     * @returns {ReturnType<typeof createApi>["endpoints"]["deleteWorkspace"]} The RTK Mutation endpoint for deleting a workspace.
     * @invalidates {Collections.Workspaces} Invalidates the `Collections.Workspaces` tag.
     * @invalidates {Collections.Workspaces, { type: Collections.Workspaces, id: id }} Invalidates the specific workspace being deleted.
     */
    deleteWorkspace: builder.mutation<Pick<Workspace, "id">, string>({
        queryFn: async id => {
            const workspaceDoc = doc(firestore, Collections.Workspaces, id).withConverter(workspaceConverter);
            await updateDoc(workspaceDoc, { status: EntityStatus.ARCHIVED });
            return { data: { id } };
        },
        invalidatesTags: (_result, _error, id) => [Collections.Workspaces, { type: Collections.Workspaces, id }],
    }),
    /**
     * A mutation that removes a location from a workspace
     *
     * @param {{ placeId: string, workspaceId: string }} args The workspace to update and the placeId to remove
     * @returns {ReturnType<typeof createApi>["endpoints"]["removeLocationFromWorkspace"]} The RTK Mutation endpoint for removing a location from a workspace.
     * @invalidates {Collections.Workspaces} Invalidates the `Collections.Workspaces` tag.
     * @invalidates {Collections.Workspaces, { type: Collections.Workspaces, id: workspace.id }} Invalidates the specific workspace being updated.
     */
    removeLocationFromWorkspace: builder.mutation<Workspace, { placeId: string; workspaceId: string }>({
        queryFn: async ({ placeId, workspaceId }) => {
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId).withConverter(workspaceConverter);
            const workspaceSnapshot = await getDoc(workspaceDoc);
            const workspace = workspaceSnapshot.data();

            if (!workspace) return { data: {} as Workspace };

            const updatedLocations = (workspace.locations ?? []).filter(xPlaceId => xPlaceId !== placeId);

            const updatedWorkspace = { ...workspace, locations: updatedLocations };
            // TODO COME FIX AFTER REMOVING ENUM TYPE
            await updateDoc(workspaceDoc, updatedWorkspace as any);

            return { data: updatedWorkspace };
        },
        invalidatesTags: (_result, _error, { workspaceId }) => [
            Collections.Workspaces,
            { type: Collections.Workspaces, id: workspaceId },
        ],
    }),
    /**
     * A mutation that adds a location to a workspace
     *
     * @param {{ placeId: string, workspaceId: string }} args The workspace to update and the placeId to add
     * @returns {ReturnType<typeof createApi>["endpoints"]["addLocationToWorkspace"]} The RTK Mutation endpoint for adding a location to a workspace.
     * @invalidates {Collections.Workspaces} Invalidates the `Collections.Workspaces` tag.
     * @invalidates {Collections.Workspaces, { type: Collections.Workspaces, id: workspace.id }} Invalidates the specific workspace being updated.
     */
    addLocationToWorkspace: builder.mutation<
        Workspace,
        { placeId: string; workspaceId: string; backgroundImage?: string }
    >({
        queryFn: async ({ placeId, workspaceId, backgroundImage }) => {
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId).withConverter(workspaceConverter);
            const workspaceSnapshot = await getDoc(workspaceDoc);
            const workspace = workspaceSnapshot.data();

            if (!workspace) return { data: {} as Workspace };

            const updatedLocations = Array.from(new Set(workspace.locations ?? []).add(placeId));
            const updatedWorkspace = { ...workspace, locations: updatedLocations, backgroundImage };
            // TODO COME FIX AFTER REMOVING ENUM TYPE
            await updateDoc(workspaceDoc, updatedWorkspace as any);

            return { data: updatedWorkspace };
        },
        invalidatesTags: (_result, _error, { workspaceId }) => [
            Collections.Workspaces,
            { type: Collections.Workspaces, id: workspaceId },
        ],
    }),
});
