import React, { FC, ReactNode } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Grid, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";

interface CreateConnectionDialogProps {
    open: boolean;
    handleClose: () => void;
    items: { name: string; icon: ReactNode; onClick?: () => void; secondaryText?: ReactNode }[];
}

export const CreateConnectionDialog: FC<CreateConnectionDialogProps> = ({ open, handleClose, items }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            fullWidth
            maxWidth="sm"
            disableEscapeKeyDown
        >
            <Grid container p={2} spacing={2} direction="column">
                <Grid item container justifyContent="space-between" alignItems="">
                    <Typography variant="h6" component="div">
                        Add a new connection
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item>
                    <TextField label="Search for app" type="search" fullWidth />
                    <List sx={{ width: "100%" }}>
                        {items.map(item => (
                            <ListItemButton onClick={item.onClick} key={item.name}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.name} secondary={item.secondaryText} />
                            </ListItemButton>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Dialog>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
