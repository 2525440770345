/**
 * Roles of the users in a given data entity.
 * Follows pattern from https://firebase.google.com/docs/firestore/solutions/role-based-access
 */
export const Roles = {
    Owner: "owner",
    Editor: "editor",
    Viewer: "viewer",
} as const;

export type RoleKey = keyof typeof Roles;

export type RoleObject = {
    [key in (typeof Roles)[RoleKey]]: string[];
};

export enum Role {
    Owner = "owner",
    Editor = "editor",
    Viewer = "viewer",
}

// this enum is used by the backend to determine which platform to use for targeting
export enum ThirdPartyPlatforms {
    GoogleCsv = "google",
    All = "all",
    GoogleSync = "googleSync",
}

export enum CustomDataUploadTypes {
    Customer = "customer",
    GoogleAds = "google ads",
    MatchedLocations = "matched locations",
}

export enum EntityStatus {
    ARCHIVED = "archived",
    ACTIVE = "active",
}
