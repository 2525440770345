export enum FeatureNames {
    Auth = "auth",
    BusinessCustomerData = "businessCustomerData",
    Location = "location",
    Map = "map",
    Theme = "theme",
    UI = "ui",
    Workspace = "workspace",
    Grid = "grid",
    Connection = "connection",
}
