import { FC } from "react";

import { Grid } from "@mui/material";

import MagicBird from "../../assets/images/MagicBird.png";
import { CheckListItem } from "../CheckListItem";
import { IllustratedDialog } from "../IllustratedDialog";

interface WelcomeModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const WelcomeModal: FC<WelcomeModalProps> = ({ isOpen, onClose }) => {
    const primaryAction = {
        label: "Let's get started!",
        onClick: () => {
            onClose();
        },
    };

    return (
        <IllustratedDialog
            isOpen={isOpen}
            onDismiss={onClose}
            imageSource={MagicBird}
            title="Welcome to HoneyGrid!"
            description="Using HoneyGrid AI, we've created a custom grid to target your neighborhoods using the following data:"
            primaryAction={primaryAction}
        >
            <Grid item>
                <Grid container spacing={2} direction="column">
                    <CheckListItem text={"20 mile radius of your business"} />
                    <CheckListItem text={"New move-ins"} />
                    <CheckListItem text={"Top home values"} />
                    <CheckListItem text={"Top incomes"} />
                </Grid>
            </Grid>
        </IllustratedDialog>
    );
};
