export const layers = [
    {
        name: "Zip Codes",
        id: "zipcodes",
    },
    {
        name: "Counties",
        id: "counties",
    },
] as const;

export type LayerIds = (typeof layers)[number]["id"];

export type LayerType = (typeof layers)[number];

export type LayerState = {
    [key in LayerIds]: boolean;
};

export const INCLUSION_TOOL = "inclusion";
export const EXCLUSION_TOOL = "exclusion";
export const TOOLS = [INCLUSION_TOOL, EXCLUSION_TOOL] as const;
export type MapTool = (typeof TOOLS)[number] | null;
