import { createSelector } from "@reduxjs/toolkit";
import { CustomTargetingArea } from "honeygrid-types";

import { RootState } from "../../initStore";

export const gridData = (state: RootState) => state.grid.grid;
export const targetingFilters = (state: RootState) => state.grid.targetingFilters;
export const customTargetingAreas = createSelector(
    (state: RootState) => state.grid.grid,
    grid => grid?.customTargetingAreas ?? ({} as CustomTargetingArea),
);
export const radius = (state: RootState) => state.grid.grid?.radius;
export const targetingType = (state: RootState) => state.grid.grid?.targetingType;
export const manualFilters = (state: RootState) => state.grid.grid?.manualFilters;
