import { invalideCellsCacheAction, updateDataSourceQueryAction } from "@/redux/services/firebaseService";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";
import {
    DocumentChange,
    QuerySnapshot,
    collection,
    doc,
    getDocs,
    getFirestore,
    onSnapshot,
    setDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { DataSource, DataSourceStatus, DeleteDataSourceRequest, DeleteDataSourceResponse } from "honeygrid-types";
import { enqueueSnackbar } from "notistack";

import { Collections } from "../collections";
import { dataSourceConverter } from "./converters";
import { Builder } from "./types";

export const dataSourcesEndpoints = (
    builder: Builder,
    apiUrl?: string,
    firestore = getFirestore(),
    functions = getFunctions(),
) => ({
    /**
     * A query that retrieves all data sources for a given workspace from the Firestore database.
     * @param {{ workspaceId: string }} params The workspace ID to retrieve grids for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getDataSource"]} The RTK Query endpoint for retrieving all dataSource for a given workspace.
     */
    getDataSources: builder.query<DataSource[], { workspaceId: string }>({
        queryFn: async ({ workspaceId }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: [] as DataSource[] };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const dataSourceSubCollection = collection(workspaceDoc, Collections.DataSources).withConverter(
                dataSourceConverter,
            );
            const dataSourceSnapshot = await getDocs(dataSourceSubCollection);
            const dataSources: DataSource[] = [];
            dataSourceSnapshot.forEach(doc => {
                dataSources.push(doc.data());
            });
            return { data: dataSources };
        },
        // Somebody wants this data
        onCacheEntryAdded: async ({ workspaceId }, { cacheEntryRemoved, dispatch }) => {
            let unsubscribe;
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return;
            }
            try {
                const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
                const dataSourceSubCollection = collection(workspaceDoc, Collections.DataSources).withConverter(
                    dataSourceConverter,
                );
                // Create our subscription to the data source subcollection and inject the data into the RTK Query cache
                unsubscribe = onSnapshot(dataSourceSubCollection, snapshot =>
                    handleDataSourceSnapshot(snapshot, dispatch, workspaceId),
                );
            } catch (error) {
                console.error("error in getDataSources", error);
                throw new Error("could not fetch data sources");
            }
            // This promise will resolve when the cache entry is removed from RTK Query (probably much later than the previous lines)
            // Nobody wants this data anymore
            await cacheEntryRemoved;
            unsubscribe?.();
        },
        keepUnusedDataFor: 3600,
        providesTags: [Collections.DataSources],
    }),

    /**
     * A query that retrieves all data sources for a given workspace from the Firestore database.
     * @param {{ workspaceId: string }} params The workspace ID to retrieve grids for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getDataSource"]} The RTK Query endpoint for retrieving all dataSource for a given workspace.
     */
    deleteDataSource: builder.mutation<
        DeleteDataSourceResponse,
        { workspaceId: string; dataId: string; fileName: string }
    >({
        queryFn: async ({ workspaceId, dataId, fileName }) => {
            const deleteDataSource = httpsCallableFromURL<DeleteDataSourceRequest, DeleteDataSourceResponse>(
                functions,
                `${apiUrl}/api/workspaces/datasource/delete`,
            );
            let response;
            try {
                response = await deleteDataSource({
                    workspaceId,
                    dataId,
                    fileName,
                });
            } catch (error) {
                console.error("error in deleteDataSource", error);
                enqueueSnackbar("Could not delete data source", { variant: "error" });
                return { error };
            }
            enqueueSnackbar("Data source deleted", { variant: "success" });
            return response;
        },
        invalidatesTags: [Collections.DataSources, Collections.Cells],
    }),

    /**
     * A mutation that creates a data source document for a given workspace in the Firestore database.
     * @param {{ workspaceId: string; dataSource: DataSource }} params The workspace ID to create a data source for and the data source to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createDataSource"]} The RTK Query endpoint for creating a data source for a given workspace.
     */
    createDataSource: builder.mutation<DataSource, { workspaceId: string; dataSource: Omit<DataSource, "id"> }>({
        queryFn: async ({ workspaceId, dataSource }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: {} as DataSource };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const dataSourceSubCollection = collection(workspaceDoc, Collections.DataSources).withConverter(
                dataSourceConverter,
            );
            const newDataSourceRef = doc(dataSourceSubCollection);
            // If no data ID is passed, use the ID of the new data source document.
            dataSource.dataId = dataSource.dataId ?? newDataSourceRef.id;
            await setDoc(newDataSourceRef, dataSource);
            return { data: { ...dataSource, id: newDataSourceRef.id } };
        },
        invalidatesTags: [Collections.DataSources],
    }),
});

const handleDataSourceSnapshot = (
    snapshot: QuerySnapshot<DataSource>,
    dispatch: ThunkDispatch<any, any, AnyAction>,
    workspaceId: string,
) => {
    dispatch(updateDataSourceQueryAction(workspaceId, updateGetDataSourcesRecipe, snapshot, dispatch));
};

const updateGetDataSourcesRecipe = (
    draft: MaybeDrafted<DataSource[]>,
    snapshot: QuerySnapshot<DataSource>,
    dispatch: ThunkDispatch<any, any, AnyAction>,
) => {
    let dataSources: DataSource[] = draft.map(dataSource => {
        return JSON.parse(JSON.stringify(dataSource));
    });

    snapshot.docChanges().forEach(change => {
        if (change.type === "modified") {
            handleModifiedDataSource(change, dataSources, dispatch);
        } else if (change.type === "added") {
            handleAddedDataSource(change, dataSources);
        } else if (change.type === "removed") {
            dataSources = handleDeletedDataSource(change, dataSources);
        }
    });
    return dataSources;
};

const handleModifiedDataSource = (
    change: DocumentChange<DataSource>,
    dataSources: DataSource[],
    dispatch: ThunkDispatch<any, any, AnyAction>,
) => {
    const dataSource = change.doc.data();
    const previousDataSource = dataSources.find(dataSource => dataSource.id === change.doc.id);
    const previousStatus = previousDataSource?.status ?? DataSourceStatus.Ready;
    if (previousStatus === DataSourceStatus.Processing && dataSource.status === DataSourceStatus.Ready) {
        // Invalidate the Collections.Cells cache
        dispatch(invalideCellsCacheAction());
        enqueueSnackbar(`${dataSource.name} is ready.`, {
            variant: "info",
        });
    } else if (previousStatus === DataSourceStatus.Processing && dataSource.status === DataSourceStatus.Error) {
        enqueueSnackbar(`${dataSource.name} failed to process.`, {
            variant: "error",
        });
    }
    const dataSourceIndex = dataSources.findIndex(dataSource => dataSource.id === change.doc.id);
    if (dataSourceIndex !== -1) {
        dataSources[dataSourceIndex] = dataSource;
    }
};

const handleAddedDataSource = (change: DocumentChange<DataSource>, dataSources: DataSource[]) => {
    const dataSource = change.doc.data();

    dataSources.push(dataSource);
};

const handleDeletedDataSource = (change: DocumentChange<DataSource>, dataSources: DataSource[]) => {
    return dataSources.filter(dataSource => dataSource.id !== change.doc.id);
};
