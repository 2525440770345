import { FC } from "react";

import { Check } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

interface CheckListItemProps {
    text: string;
    checkColor?: "inherit" | "disabled" | "action" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
    textColor?: string;
}

export const CheckListItem: FC<CheckListItemProps> = ({ text, checkColor = "inherit", textColor }) => {
    return (
        <Grid item>
            <Typography color={textColor} variant="body2" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Check color={checkColor} /> {text}
            </Typography>
        </Grid>
    );
};
