import { Theme } from "@/theme";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { authSlice } from "../auth/authSlice";
import { FeatureNames } from "../names";

export interface ThemeState {
    theme: Theme;
}

const initialState: ThemeState = {
    theme: Theme.Light,
};

export const themeSlice = createSlice({
    name: FeatureNames.Theme,
    initialState,
    reducers: {
        setTheme: (state, action: PayloadAction<ThemeState["theme"]>) => {
            state.theme = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(authSlice.actions.logOut, () => initialState);
    },
});

export type ThemeActions = typeof themeSlice.actions;
