import { fetchWithAuth } from "@/utils/fetchWithAuth";
import { getAuth } from "firebase/auth";
import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { Location, placeResultToLocation } from "honeygrid-types";

import { Collections } from "../collections";
import { locationConverter } from "./converters";
import { Builder } from "./types";

export const locationsEndpoints = (builder: Builder, apiUrl: string, firestore = getFirestore(), auth = getAuth()) => ({
    getPlaceDetails: builder.query<Location, string>({
        queryFn: async placeId => {
            try {
                const httpRes = await fetchWithAuth(`${apiUrl}/api/places?placeId=${placeId}`);
                if (!httpRes.ok) {
                    return { data: {} as Location };
                }

                const {
                    place: { result: res },
                } = await httpRes.json();
                const data = placeResultToLocation(res, apiUrl);
                return { data };
            } catch (err) {
                console.error("Error getting place details", err);
                return { data: {} as Location };
            }
        },
        providesTags: (_result, _error, placeId) => [{ type: Collections.Places, id: placeId }],
    }),
    /**
     * A query that retrieves all locations based on the provided placeIds from the Firestore database.
     *
     * @param void
     * @returns {ReturnType<typeof createApi>["endpoints"]["getMultipleLocations"]} The RTK Query endpoint for retrieving multiple locations
     */
    getMultipleLocations: builder.query<Location[], string[]>({
        queryFn: async placeIds => {
            if (!auth.currentUser || !placeIds.length) {
                return { data: [] };
            }
            const locationsCollection = collection(firestore, Collections.Locations).withConverter(locationConverter);
            const locationsQuery = query(locationsCollection, where("placeId", "in", placeIds));
            const locationsSnapshot = await getDocs(locationsQuery);
            const locations: Location[] = [];
            locationsSnapshot.forEach(location => locations.push(location.data()));
            return { data: locations };
        },
        providesTags: [Collections.Locations],
    }),
    /**
     * A query that retrieves a single location from the Firestore database by its ID.
     *
     * @param {string} id The ID of the location to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getLocationById"]} The RTK Query endpoint for retrieving a single location by ID.
     */
    getLocationById: builder.query<Location, string>({
        queryFn: async id => {
            const locationDoc = doc(firestore, Collections.Locations, id).withConverter(locationConverter);
            const location = await getDoc(locationDoc);
            return { data: location.data() as Location };
        },
        providesTags: (_result, _error, id) => [{ type: Collections.Locations, id }],
    }),
    /**
     * A mutation that creates a new location in the Firestore database.
     *
     * @param {Partial<Location>} location The location object to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createLocation"]} The RTK Mutation endpoint for creating a new location.
     * @invalidates {Collections.Locations} Invalidates the `Collections.Locations` tag.
     */
    createLocation: builder.mutation<Location, Partial<Location>>({
        queryFn: async location => {
            const locations = collection(firestore, Collections.Locations).withConverter(locationConverter);
            const existingLocationsQuery = query(locations, where("placeId", "==", location.placeId ?? ""));
            const existingLocations = await getDocs(existingLocationsQuery);

            const doc = existingLocations.docs.at(0)?.ref;

            if (existingLocations.empty || !doc) {
                const saveLocation = { ...location };
                const locationsCollection = collection(firestore, Collections.Locations).withConverter(
                    locationConverter,
                );
                const docRef = await addDoc(locationsCollection, saveLocation);
                const newLocation = { ...location, id: docRef.id } as Location;
                return { data: newLocation };
            }

            await updateDoc(doc, { ...location });
            return { data: { ...location, id: doc.id ?? "" } };
        },
        invalidatesTags: [Collections.Locations],
    }),
    /**
     * A mutation that updates a location in the Firestore database.
     *
     * @param {Partial<Location>} location The updated location object.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateLocation"]} The RTK Mutation endpoint for updating a location.
     * @invalidates {Collections.Locations} Invalidates the `Collections.Locations` tag.
     * @invalidates {Collections.Locations, { type: Collections.Locations, id: location.id }} Invalidates the specific location being updated.
     */
    updateLocation: builder.mutation<Pick<Location, "id">, Partial<Location>>({
        queryFn: async location => {
            const saveLocation = { ...location };
            const locationDoc = doc(firestore, Collections.Locations, location.id ?? "").withConverter(
                locationConverter,
            );
            await updateDoc(locationDoc, saveLocation);
            return { data: { id: location.id ?? "" } };
        },
        invalidatesTags: (_result, _error, location) => [
            Collections.Locations,
            { type: Collections.Locations, id: location.id },
        ],
    }),
});
