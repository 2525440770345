import { fetchWithAuth } from "@/utils/fetchWithAuth";
import {
    AccessibleGoogleAccount,
    Campaign,
    GetAccessibleGoogleAccountsRequest,
    GetAccessibleGoogleAccountsResponse,
    GetCampaignsForGoogleAccountRequest,
    GetCampaignsForGoogleAccountResponse,
    PushMicrotargetsToGoogleAdsRequest,
    PushMicrotargetsToGoogleAdsResponse,
} from "honeygrid-types";
import { enqueueSnackbar } from "notistack";

import { Builder } from "..";

export const googleAdsApiEndpoints = (builder: Builder) => ({
    /**
     * Get a list of all Google Ads accounts for a given connection.
     */
    getAccessibleGoogleAccounts: builder.query<GetAccessibleGoogleAccountsResponse, GetAccessibleGoogleAccountsRequest>(
        {
            queryFn: async ({ connectionId }) => {
                try {
                    const response = await fetchWithAuth(
                        `${import.meta.env.VITE_CF_API_URL}/firebase/google-ads/connections/${connectionId}/accounts`,
                    );
                    const { accounts } = (await response.json()) as { accounts: AccessibleGoogleAccount[] };
                    console.log("accounts", accounts);

                    return { data: accounts };
                } catch (e: any) {
                    enqueueSnackbar("Error fetching Google Ads accounts", { variant: "error" });
                    return { error: e.message };
                }
            },
        },
    ),
    /**
     * Get a list of all Google Ads campaigns for a given account.
     */
    getCampaignsForGoogleAccount: builder.query<
        GetCampaignsForGoogleAccountResponse,
        GetCampaignsForGoogleAccountRequest
    >({
        queryFn: async ({ connectionId }) => {
            try {
                const response = await fetchWithAuth(
                    `${import.meta.env.VITE_CF_API_URL}/firebase/google-ads/connections/${connectionId}/campaigns`,
                );
                const { campaigns } = (await response.json()) as { campaigns: Campaign[] };

                return { data: campaigns };
            } catch (e: any) {
                enqueueSnackbar("Error fetching Google Ads campaigns", { variant: "error" });
                return { error: e.message };
            }
        },
    }),
    /**
     * Push a list of microtargets to Google Ads.
     */
    syncToGoogleAds: builder.mutation<PushMicrotargetsToGoogleAdsResponse, PushMicrotargetsToGoogleAdsRequest>({
        queryFn: async ({ connectionId, campaignId, cellIds, isFullReplace }) => {
            try {
                const response = await fetchWithAuth(
                    `${
                        import.meta.env.VITE_CF_API_URL
                    }/firebase/google-ads/connections/${connectionId}/campaigns/${campaignId}/geo-targets`,
                    {
                        method: "POST",
                        body: JSON.stringify({ cellIds, isFullReplace }),
                    },
                );
                const { result } = (await response.json()) as { result: boolean };
                enqueueSnackbar("Microtargets synced to Google Ads", { variant: "success" });

                return { data: { result } };
            } catch (e: any) {
                enqueueSnackbar("Error syncing microtargets to Google Ads", { variant: "error" });
                return { error: e.message };
            }
        },
    }),
});
