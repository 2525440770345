import { fetchWithAuth } from "@/utils/fetchWithAuth";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
    BaseGrid,
    EntityStatus,
    GetHoneyGridMultilocationRequest,
    GetHoneyGridMultilocationResponse,
    Grid,
    PartialWithRequiredId,
    SystemGeneratedString,
    TargetingType,
} from "honeygrid-types";

import { Collections } from "../collections";
import { gridConverter } from "./converters";
import { calculateHoneygridStats } from "./transformers/calculateHoneygridStats";
import { Builder } from "./types";

export const gridsEndpoints = (
    builder: Builder,
    apiUrl: string,
    firestore = getFirestore(),
    functions = getFunctions(),
) => ({
    /**
     * A query that retrieves all non-archived grids for a given workspace from the Firestore database.
     * @param {{ workspaceId: string }} params The workspace ID to retrieve grids for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getGrids"]} The RTK Query endpoint for retrieving all non-archived grids for a given workspace.
     */
    getGrids: builder.query<Grid[], { workspaceId: string }>({
        queryFn: async ({ workspaceId }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: [] as Grid[] };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const gridSubCollection = collection(workspaceDoc, Collections.Grids).withConverter(gridConverter);
            const gridsQuery = query(
                gridSubCollection,
                where("status", "in", [EntityStatus.ACTIVE]),
                orderBy("updatedAt", "desc"),
            );

            const gridsSnapshot = await getDocs(gridsQuery);
            const grids: Grid[] = [];
            gridsSnapshot.forEach(doc => {
                grids.push(doc.data());
            });
            return { data: grids };
        },
        providesTags: [Collections.Grids],
    }),
    /**
     * A query that retrieves a single grid from the Firestore database by its ID and workspace ID.
     *
     * @param {{ workspaceId: string; gridId: string }} params The workspace ID and grid ID to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getGridById"]} The RTK Query endpoint for retrieving a single grid by ID and workspace ID.
     */
    getGridById: builder.query<Grid | null, { workspaceId: string; gridId: string }>({
        queryFn: async ({ workspaceId, gridId }) => {
            if (!workspaceId || !gridId) {
                console.log(`Missing either workspaceId or gridId. workspaceId: ${workspaceId}. gridId: ${gridId}`);

                return { data: null };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const gridDoc = doc(workspaceDoc, Collections.Grids, gridId).withConverter(gridConverter);
            const grid = await getDoc(gridDoc);
            // TODO handle undefined grid
            return { data: grid.data() as Grid };
        },
        providesTags: (_result, _error, { gridId }) => [{ type: Collections.Grids, gridId }],
    }),
    /**
     * A mutation that creates a new grid in the Firestore database.
     *
     * @param {Grid} grid The grid object to create.
     * @param {SystemGeneratedString} workspaceId The ID of the workspace that the grid belongs to.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createGrid"]} The RTK Mutation endpoint for creating a new grid.
     * @invalidates {Collections.Grids} Invalidates the `Collections.Grids` tag.
     * @invalidates {Collections.Grids, { type: Collections.Grids, id: grid.workspaceId }} Invalidates the grids for the specific workspace being updated.
     */
    createGrid: builder.mutation<Grid, { grid: BaseGrid; workspaceId?: string }>({
        queryFn: async ({ grid, workspaceId }) => {
            if (!workspaceId) {
                console.log("No workspaceId provided");
                return { data: {} as Grid };
            }

            const now = new Date().toISOString();

            const saveGrid = { ...grid, createdAt: now, updatedAt: now };
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const gridSubCollection = collection(workspaceDoc, Collections.Grids).withConverter(gridConverter);
            const docRef = await addDoc(gridSubCollection, saveGrid);
            const newGrid = { ...grid, id: docRef.id } as Grid;
            return { data: newGrid };
        },
        invalidatesTags: [Collections.Grids],
    }),
    /**
     * A mutation that updates a grid in the Firestore database.
     *
     * @param {Partial<Grid>} grid The updated grid object.
     * @param {SystemGeneratedString} workspaceId The ID of the workspace that the grid belongs to.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateGrid"]} The RTK Mutation endpoint for updating a grid.
     * @invalidates {Collections.Grids} Invalidates the `Collections.Grids` tag.
     * @invalidates {Collections.Grids, { type: Collections.Grids, id: grid.workspaceId }} Invalidates the grids for the specific workspace being updated.
     */
    updateGrid: builder.mutation<
        Pick<Grid, "id">,
        { workspaceId: SystemGeneratedString; grid: PartialWithRequiredId<Grid> }
    >({
        queryFn: async ({ workspaceId, grid }) => {
            const updatedAt = new Date().toISOString();

            const saveGrid = { ...grid, updatedAt };
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const gridDoc = doc(workspaceDoc, Collections.Grids, grid.id).withConverter(gridConverter);
            // TODO COME FIX AFTER REMOVING ENUM TYPE
            await updateDoc(gridDoc, saveGrid as any);
            return { data: { id: grid.id } };
        },
        invalidatesTags: (_result, _error, { grid }) => [Collections.Grids, { type: Collections.Grids, id: grid.id }],
    }),
    /**
     * A mutation that archives a grid in the Firestore database.
     * @param {Pick<Grid, "id">} grid The grid object to delete.
     * @param {SystemGeneratedString} workspaceId The ID of the workspace that the grid belongs to.
     * @returns {ReturnType<typeof createApi>["endpoints"]["deleteGrid"]} The RTK Mutation endpoint for deleting a grid.
     * @invalidates {Collections.Grids} Invalidates the `Collections.Grids` tag.
     * @invalidates {Collections.Grids, { type: Collections.Grids, id: grid.workspaceId }} Invalidates the grids for the specific workspace being updated.
     */
    archiveGrid: builder.mutation<
        Pick<Grid, "id">,
        { workspaceId: SystemGeneratedString; gridId: SystemGeneratedString }
    >({
        queryFn: async ({ workspaceId, gridId }) => {
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const gridDoc = doc(workspaceDoc, Collections.Grids, gridId).withConverter(gridConverter);
            await updateDoc(gridDoc, { status: EntityStatus.ARCHIVED });
            return { data: { id: gridId } };
        },
        invalidatesTags: (_result, _error, { gridId }) => [Collections.Grids, { type: Collections.Grids, id: gridId }],
    }),
    /**
     * A query that retrieves a honeygrid from firestore based on a radius and a lat/lng
     * @param {number} lat The latitude of the center of the honeygrid
     * @param {number} lng The longitude of the center of the honeygrid
     * @param {number} radius The radius of the honeygrid in kilometers
     * @param {CustomTargetingArea} customTargetingAreas The list of custom targeting areas to filter by
     * @param {string} workspaceId The ID of the workspace that the grid belongs to.
     * @param {string} targetingType The type of targeting to use for the grid
     */
    lookupHoneyGrid: builder.query<
        GetHoneyGridMultilocationResponse,
        GetHoneyGridMultilocationRequest & { targetingType?: TargetingType }
    >({
        queryFn: async params => {
            try {
                // Only send args for the current targeting type
                const { targetingType, ...lookupArgs } = params;
                // Get the current user's ID token from Firebase Auth

                const searchParams = new URLSearchParams({ workspaceId: lookupArgs.workspaceId });
                if (targetingType === TargetingType.RADIUS) {
                    searchParams.append("radiusTargets", JSON.stringify(lookupArgs.radiusTargets));
                } else {
                    searchParams.append("customTargetingAreas", JSON.stringify(lookupArgs.customTargetingAreas));
                }
                const response = await fetchWithAuth(`${apiUrl}/api/grids/lookup?${searchParams.toString()}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                const data = (await response.json()) as Pick<
                    GetHoneyGridMultilocationResponse,
                    "geoJson" | "customDataKeys"
                >;

                const cellIds = new Set<string>();
                // generate cellMap
                data.geoJson.features.forEach(feature => {
                    if (cellIds.has(feature.properties.id)) return;
                    cellIds.add(feature.properties.id);
                });
                // generate aggregatedDemographics
                const aggregatedDemographics = calculateHoneygridStats(data.geoJson, data.customDataKeys);

                return { data: { ...data, aggregatedDemographics } };
            } catch (e) {
                return { error: { status: "CUSTOM_ERROR", data: e } };
            }
        },
        providesTags: [Collections.Cells],
    }),

    /**
     * Endpoint to call the `modifyFacebookTargeting` Cloud Function.
     * @returns {ReturnType<typeof createApi>["endpoints"]["modifyFacebookTargeting"]} The RTK Mutation endpoint for modifying targeting config
     */
    modifyFacebookTargeting: builder.mutation<
        { message: string; success: boolean },
        { cellIds: string[]; accessToken: string; adSetId: string }
    >({
        queryFn: async data => {
            const modifyFacebookTargeting = httpsCallableFromURL(functions, `${apiUrl}/api/modifyFacebookTargeting`);
            const result = await modifyFacebookTargeting(data);
            return { data: result.data as any };
        },
    }),
});
