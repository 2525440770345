import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

import firebaseInit from "@/utils/firebase";

import { Collections } from "../store/slices";
import { accountsEndpoints } from "../store/slices/firestore/accounts";
import { campaignsEndpoints } from "../store/slices/firestore/campaigns";
import { connectionsEndpoints } from "../store/slices/firestore/connections";
import { customDataEndpoints } from "../store/slices/firestore/customData";
import { dataSourcesEndpoints } from "../store/slices/firestore/dataSources";
import { gridsEndpoints } from "../store/slices/firestore/grids";
import { invitedUsersEndpoints } from "../store/slices/firestore/invitedUsers";
import { locationsEndpoints } from "../store/slices/firestore/locations";
import { marketingEndpoints } from "../store/slices/firestore/marketing";
import { paymentLinksEndpoints } from "../store/slices/firestore/paymentLinks";
import { usersEndpoints } from "../store/slices/firestore/users";
import { workspacesEndpoints } from "../store/slices/firestore/workspaces";
import { googleAdsApiEndpoints } from "../store/slices/googleAds/googleAdsApiSlice";

firebaseInit();

/**
 * Creates a new [RTK Query API](https://redux-toolkit.js.org/rtk-query/api/createApi) object with the given configuration.
 */
export const createFirestoreApi = (apiUrl: string) =>
    createApi({
        baseQuery: fakeBaseQuery(),
        reducerPath: "firestore",
        tagTypes: [
            Collections.Users,
            Collections.Workspaces,
            Collections.Campaigns,
            Collections.Grids,
            Collections.Locations,
            Collections.Marketing,
            Collections.Places,
            Collections.Accounts,
            Collections.InvitedUsers,
            Collections.CustomData,
            Collections.DataSources,
            Collections.Cells,
        ],
        endpoints: builder => ({
            ...campaignsEndpoints(builder),
            ...gridsEndpoints(builder, apiUrl),
            ...locationsEndpoints(builder, apiUrl),
            ...usersEndpoints(builder),
            ...workspacesEndpoints(builder),
            ...marketingEndpoints(builder),
            ...accountsEndpoints(builder),
            ...paymentLinksEndpoints(builder),
            ...invitedUsersEndpoints(builder, apiUrl),
            ...customDataEndpoints(builder, apiUrl),
            ...dataSourcesEndpoints(builder, apiUrl),
            ...connectionsEndpoints(builder, apiUrl),
            ...googleAdsApiEndpoints(builder),
        }),
    });

export const firebaseApi = createFirestoreApi(import.meta.env.VITE_API_URL);

export const updateDataSourceQueryAction = (
    workspaceId: string,
    updateGetDataSourcesRecipe: any,
    snapshot: any,
    dispatch: any,
) =>
    firebaseApi.util.updateQueryData("getDataSources", { workspaceId }, draft =>
        updateGetDataSourcesRecipe(draft, snapshot, dispatch),
    );

export const invalideCellsCacheAction = () => firebaseApi.util.invalidateTags([Collections.Cells]);

/**
 * hooks ordered alphabetically by collection
 */
export const {
    // Accounts
    useCreateAccountMutation,
    useGetAccountByIdQuery,
    useUpdateAccountMutation,
    useGetAccountsByUserIdQuery,
    // Campaigns
    useArchiveCampaignMutation,
    useCreateCampaignMutation,
    useGetCampaignByIdQuery,
    useGetCampaignsQuery,
    useLazyGetCampaignByIdQuery,
    useLazyGetCampaignsQuery,
    useUpdateCampaignMutation,
    // Connections
    useGetConnectionsQuery,
    useGetConnectionByIdQuery,
    useDeleteConnectionMutation,
    useUpdateConnectionMutation,
    useGetCampaignsForGoogleAccountQuery,
    // Custom Data
    useGetCustomDataQuery,
    useLazyGetCustomDataQuery,
    useUploadCustomDataMutation,
    // Data Sources
    useGetDataSourcesQuery,
    useDeleteDataSourceMutation,
    useCreateDataSourceMutation,
    // Google Ads
    useGetAccessibleGoogleAccountsQuery,
    useSyncToGoogleAdsMutation,
    // Grids
    useArchiveGridMutation,
    useCreateGridMutation,
    useGetGridByIdQuery,
    useGetGridsQuery,
    useLazyGetGridByIdQuery,
    useLazyGetGridsQuery,
    useUpdateGridMutation,
    useModifyFacebookTargetingMutation,
    useLazyLookupHoneyGridQuery,
    useLookupHoneyGridQuery,
    // InvitedUsers,
    useGetInvitedUsersQuery,
    useCreateInvitedUserMutation,
    useDeleteInvitedUserMutation,
    useInviteUserAndReturnUrlMutation,
    // Locations
    useAddLocationToWorkspaceMutation,
    useCreateLocationMutation,
    useGetLocationByIdQuery,
    useGetMultipleLocationsQuery,
    useLazyGetLocationByIdQuery,
    useLazyGetMultipleLocationsQuery,
    useRemoveLocationFromWorkspaceMutation,
    useUpdateLocationMutation,
    // Marketing
    useCreateMarketingUserMutation,
    // Places
    useGetPlaceDetailsQuery,
    useLazyGetPlaceDetailsQuery,
    // PaymentLinks
    useGetPaymentLinkQuery,
    // Users
    useCreateUserMutation,
    useGetUserByIdQuery,
    useGetUsersByIdQuery,
    useGetUsersQuery,
    useLazyGetUserByIdQuery,
    useLazyGetUsersByIdQuery,
    useLazyGetUsersQuery,
    useUpdateUserMutation,
    // Workspaces
    useCreateWorkspaceMutation,
    useGetWorkspaceByIdQuery,
    useGetWorkspacesQuery,
    useLazyGetWorkspaceByIdQuery,
    useLazyGetWorkspacesQuery,
    useUpdateWorkspaceMutation,
    useDeleteWorkspaceMutation,
} = firebaseApi;
