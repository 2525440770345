import * as auth from "./auth/authSelectors";
import * as connections from "./connections/connectionsSelectors";
import * as customerData from "./customerData/customerDataSelectors";
import * as grid from "./grid/gridSelectors";
import * as locations from "./location/locationSlice";
import * as map from "./map/mapSelectors";
import * as theme from "./theme/themeSelectors";
import * as ui from "./ui/uiSelectors";

export * from "./auth/authSlice";
export * from "./collections";
export * from "./customerData/customerDataSlice";
export * from "./firestore";
export * from "./location/locationSlice";
export * from "./map/mapSlice";
export * from "./names";
export * from "./theme/themeSlice";
export * from "./ui/uiSlice";
export * from "./workspace";
export * from "./collections";
export * from "./grid/gridSlice";
export * from "./connections/connectionsSlice";

export const selectors = {
    ...auth,
    ...customerData,
    ...locations,
    ...theme,
    ...ui,
    ...grid,
    ...map,
    ...connections,
};
