import { addDoc, collection, getFirestore } from "firebase/firestore";
import { Marketing } from "honeygrid-types";

import { Collections } from "../collections";
import { marketingConverter } from "./converters";
import { Builder } from "./types";

export const marketingEndpoints = (builder: Builder, firestore = getFirestore()) => ({
    /**
     * A mutation that creates a marketing entry so that we can send users marketing emails.
     * @param {Marketing} marketing The marketing object to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createMarketingUser"]} The RTK Mutation endpoint for creating a new marketing entry.
     */
    createMarketingUser: builder.mutation<Marketing, { marketing: Partial<Marketing> }>({
        queryFn: async ({ marketing }) => {
            const saveMarketing = { ...marketing };
            const marketingCollection = collection(firestore, Collections.Marketing).withConverter(marketingConverter);
            const docRef = await addDoc(marketingCollection, saveMarketing);
            const newMarketingEntry = { ...marketing, id: docRef.id } as Marketing;
            return { data: newMarketingEntry };
        },
    }),
});
