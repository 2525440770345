import {
    FieldPath,
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    or,
    query,
    updateDoc,
    where,
} from "firebase/firestore";
import { Account, BaseAccount, PartialWithRequiredId } from "honeygrid-types";

import { Collections } from "../collections";
import { accountConverter } from "./converters";
import { Builder } from "./types";

export const accountsEndpoints = (builder: Builder, firestore = getFirestore()) => ({
    /**
     * A query that retrieves a single account from the Firestore database by its ID.
     *
     * @param {{ accountId: string }} params The account ID to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getAccountById"]} The RTK Query endpoint for retrieving a single account by ID.
     */
    getAccountById: builder.query<Account, { accountId: string }>({
        queryFn: async ({ accountId }) => {
            if (!accountId) {
                console.debug("No accountId provided");
                return { data: {} as Account };
            }
            const accountDoc = doc(firestore, Collections.Accounts, accountId).withConverter(accountConverter);
            const account = await getDoc(accountDoc);
            return { data: account.data() as Account };
        },
        providesTags: (_result, _error, { accountId }) => [{ type: Collections.Accounts, accountId }],
    }),
    /**
     * A mutation that creates a new account in the Firestore database.
     *
     * @param {BaseAccount} account The account to create.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createAccount"]} The RTK Mutation endpoint for creating a new account.
     * @invalidatesTags [Collections.Accounts]
     */
    createAccount: builder.mutation<Account, BaseAccount>({
        queryFn: async account => {
            const accountCollection = collection(firestore, Collections.Accounts).withConverter(accountConverter);
            const saveAccount: BaseAccount = {
                ...account,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
            };
            const docRef = await addDoc(accountCollection, saveAccount);
            const newAccount = { ...saveAccount, id: docRef.id };
            return { data: newAccount as Account };
        },
        invalidatesTags: [Collections.Accounts],
    }),
    /**
     * A mutation that updates an existing account in the Firestore database.
     *
     * @param {PartialWithRequiredId<Account>} account The account to update.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateAccount"]} The RTK Mutation endpoint for updating an existing account.
     */
    updateAccount: builder.mutation<Account, PartialWithRequiredId<Account>>({
        queryFn: async account => {
            const accountDoc = doc(firestore, Collections.Accounts, account.id).withConverter(accountConverter);
            const updateAccount = {
                ...account,
                updatedAt: new Date().toISOString(),
            };
            await updateDoc(accountDoc, updateAccount);
            return { data: updateAccount as Account };
        },
        invalidatesTags: (_result, _error, { id }) => [{ type: Collections.Accounts, id }],
    }),
    // get accounts where a user is an owner
    getAccountsByUserId: builder.query<Account[], { userId?: string }>({
        queryFn: async ({ userId }) => {
            if (userId === undefined) {
                console.debug("No userId provided");
                return { data: [] as Account[] };
            }
            const accountsCollection = collection(firestore, Collections.Accounts).withConverter(accountConverter);
            const accountsQuery = query(
                accountsCollection,
                or(
                    where(new FieldPath("roles", "owner"), "array-contains", userId),
                    where(new FieldPath("roles", "editor"), "array-contains", userId),
                    where(new FieldPath("roles", "viewer"), "array-contains", userId),
                ),
            );
            const accounts = await getDocs(accountsQuery);
            return { data: accounts.docs.map(doc => doc.data()) };
        },
        providesTags: (_result, _error, { userId }) => [{ type: Collections.Accounts, userId }],
    }),
});
