import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Optional, Workspace } from "honeygrid-types";

import { authSlice } from "./auth/authSlice";
import { FeatureNames } from "./names";

export interface WorkspaceState {
    selectedWorkspaceId?: string;
    workspaces: Record<string, Workspace>;
}

const initialState: WorkspaceState = {
    workspaces: {},
};

export const workspaceSlice = createSlice({
    name: FeatureNames.Workspace,
    initialState,
    reducers: {
        createWorkspace: (state, action: PayloadAction<Optional<Workspace, "id">>) => {
            const id = action.payload.id ?? nanoid();
            state.workspaces[id] = { ...action.payload, id };
            state.selectedWorkspaceId = id;
        },
        updateWorkspace: (state, action: PayloadAction<Omit<Partial<Workspace>, "id"> & { id: string }>) => {
            const id = action.payload.id;
            const workspace = state.workspaces[id];
            state.workspaces[id] = { ...workspace, ...action.payload };
        },
        selectWorkspace: (state, action: PayloadAction<string>) => {
            state.selectedWorkspaceId = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(authSlice.actions.logOut, () => initialState);
    },
});

export type WorkspaceActions = typeof workspaceSlice.actions;
