import { SelectorFn } from "../../../types";
import { BusinessCustomerData } from "../../slices";

export const customerData: SelectorFn<BusinessCustomerData | null> = state => {
    const workspaceId = state.workspace.selectedWorkspaceId;
    if (!workspaceId) return null;

    const customerDataIds = state.businessCustomerData.customerDataByWorkspaceId[workspaceId];

    if (!customerDataIds) return null;

    const customerDataId = customerDataIds[0];
    return state.businessCustomerData.customerData[customerDataId];
};
