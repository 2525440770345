import { SelectorFn } from "../../../types";
import { RootState } from "../../initStore";

export const isComingSoonDialogOpen: SelectorFn<boolean> = ({
    ui: {
        modalState: { isComingSoonDialogOpen },
    },
}) => isComingSoonDialogOpen;

export const isWorkspaceEditorOpen: SelectorFn<boolean> = ({
    ui: {
        modalState: { isWorkspaceEditorOpen },
    },
}) => isWorkspaceEditorOpen;

export const isWorkspaceSelectorOpen: SelectorFn<boolean> = ({
    ui: {
        modalState: { isWorkspaceSelectorOpen },
    },
}) => isWorkspaceSelectorOpen;

export const isInviteUsersDialogOpen: SelectorFn<boolean> = ({
    ui: {
        modalState: { isInviteUsersDialogOpen },
    },
}) => isInviteUsersDialogOpen;

export const isProfileButtonShown: SelectorFn<boolean> = ({
    ui: {
        headerState: { isProfileButtonShown },
    },
}) => isProfileButtonShown;

export const mapViewState: SelectorFn<RootState["ui"]["mapViewState"]> = ({ ui: { mapViewState } }) => mapViewState;
export const mapViewBounds: SelectorFn<RootState["ui"]["mapViewBounds"]> = ({ ui: { mapViewBounds } }) => mapViewBounds;
export const isSyncToGoogleDialogOpen: SelectorFn<boolean> = ({
    ui: {
        modalState: { isSyncToGoogleDialogOpen },
    },
}) => isSyncToGoogleDialogOpen;
