// Import the functions you need from the SDKs you need
import { getAnalytics, isSupported as isAnalyticsSupported } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore, initializeFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_APP_CONFIG);

export const EMAIL_STORAGE_KEY = "hgEmailForSignIn";
export const FIRST_NAME_STORAGE_KEY = "hgNewUserFirstName";
export const LAST_NAME_STORAGE_KEY = "hgNewUserLastName";
// Initialize Firebase
export default () => {
    const app = initializeApp(firebaseConfig);
    initializeFirestore(app, { ignoreUndefinedProperties: true });

    if (import.meta.env.DEV) {
        connectAuthEmulator(getAuth(), "http://localhost:9099", { disableWarnings: true });
        connectFirestoreEmulator(getFirestore(), "localhost", 8080);
        connectFunctionsEmulator(getFunctions(), "127.0.0.1", 5001);
        connectStorageEmulator(getStorage(), "127.0.0.1", 9199);
    } else {
        // initialize analytics
        isAnalyticsSupported().then(() => getAnalytics(app));
    }
};
