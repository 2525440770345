import { Role } from "./common";
import { SystemGeneratedString } from "./utility";

export interface User {
    email?: string;
    phone?: string;
    id: SystemGeneratedString;
    firstName?: string;
    lastName?: string;
    defaultWorkspace?: string;
    uiPreferences: {
        theme?: Theme;
    };
    milestones: Milestones;
    createdDate?: string;
}

type Theme = "light" | "dark";

type Milestones = Array<UserMilestones>;
export enum UserMilestones {
    HAS_SEEN_GRID_WELCOME_SCREEN = "hasSeenGridWelcomeScreen",
    HAS_CREATED_CAMPAIGN = "hasCreatedCampaign",
}

export interface InvitedUser {
    id?: SystemGeneratedString;
    email: string;
    workspaceId: string;
    role: Role;
}
