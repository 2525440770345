/**
 * Each H3 cell is about 0.496 miles across at our current resolution (8)
 * To get a 20-mile real world radius, we need to multiply that cell distance
 * by 40
 */
export const RADIUS_OPTIONS = [5, 10, 20, 40, 60];
export const DEFAULT_RADIUS_VALUE = 20;
export const NAME_CHARACTER_LIMIT = 200;
export const DEFAULT_LATITUDE = 30.3060973;
export const DEFAULT_LONGITUDE = -97.9577071;
