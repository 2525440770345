import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";

import { actions } from "../store/actionsAndReducers";
import { RootState } from "../store/initStore";
import type { AppDispatch } from "../types";

export const useAppDispatch: () => [AppDispatch, typeof actions] = () => {
    return [useDispatch<AppDispatch>(), actions];
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
