import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BusinessCustomer, BusinessCustomerError, Optional } from "honeygrid-types";

import { authSlice } from "../auth/authSlice";
import { FeatureNames } from "../names";

export interface BusinessCustomerData {
    id: string;
    workspaceId: string;
    source: string;
    lastUpdated: string;
    customers: BusinessCustomer[];
    errors: BusinessCustomerError[];
}

export interface CustomerDataState {
    customerData: Record<string, BusinessCustomerData>;
    customerDataByWorkspaceId: Record<string, string[]>;
}

const initialState: CustomerDataState = {
    customerData: {},
    customerDataByWorkspaceId: {},
};

export const businessCustomerDataSlice = createSlice({
    name: FeatureNames.BusinessCustomerData,
    initialState,
    reducers: {
        setCustomerData: (state, action: PayloadAction<Optional<BusinessCustomerData, "id">>) => {
            const id = action.payload.id ?? nanoid();
            const customerData = { ...action.payload, id };

            state.customerData[id] = customerData;
            state.customerDataByWorkspaceId[customerData.workspaceId] ??= [];
            state.customerDataByWorkspaceId[customerData.workspaceId].push(id);
        },
    },
    extraReducers: builder => {
        builder.addCase(authSlice.actions.logOut, () => initialState);
    },
});

export type BusinessCustomerDataSliceActions = typeof businessCustomerDataSlice.actions;
