import { type Action, type ThunkAction, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { firebaseApi } from "../services/firebaseService";
import { getCombinedReducer } from "./actionsAndReducers";

const rootReducer = getCombinedReducer({
    [firebaseApi.reducerPath]: firebaseApi.reducer,
});

/**
 * Helper function to setup redux store
 * This let's us use this here as well as in test setup
 * @returns initialized store
 */
export const setupStore = (preloadedState?: Partial<RootState>) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware().concat(firebaseApi.middleware),
        preloadedState,
    });
    setupListeners(store.dispatch);
    return store;
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
