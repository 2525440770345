import { FC, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { EVENT_TYPES, formatCurrency, logEvent } from "@/utils";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
    Avatar,
    Box,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Select,
    Typography,
    styled,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "@redux/hooks/useAppState";
import { useArchiveGridMutation } from "@redux/services/firebaseService";
import { selectors } from "@redux/store";
import { Campaign, Grid as GridType } from "honeygrid-types";
import { enqueueSnackbar } from "notistack";

import { DeleteConfirmationDialog } from "../DeleteConfirmationDialog";
import { TargetingAreasMetricRow } from "../TargetingAreasMetricRow";
import { TargetingCoverageMetricRow } from "../TargetingCoverageMetricRow";

/** Made a custom card component
 * as material UI adds a margin bottom on the last child
 * This is simply a wrapper that takes in the size of its children
 * 
 * We can add styles by using the sx props - same as material ui
 * 
 * @usage
 * <Card
      onClick={handlePreviewJob}
      sx={{
        borderRadius: '10px',
        padding: '16px',
        cursor: 'pointer',
        background: snapshot.isDragging ? '#F2F2F2' : '#FFF',
      }}
    >
 */

interface StaffCardProps {
    avatar?: string;
    /**
     * Image URL for the avatar
     */

    name: string;
}

interface StatsCardProps {
    metric: string;
    stat: string;
}

interface CampaignCardProps {
    campaign: Campaign;
    onChangeStatus?: () => void;
}

interface GridCardProps {
    grid: GridType;
    isSelected?: boolean;
}

const Card = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "left",
    fontWeight: "bold",
    borderRadius: "8px",
}));

const StaffCard: FC<StaffCardProps> = ({ avatar, name }) => {
    return (
        <Box sx={{ minWidth: "120px", marginRight: "8px" }} data-testid="card-component">
            <Card>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <div data-testid="avatar-component">
                            <Avatar src={avatar}>{!avatar && name.charAt(0).toUpperCase()}</Avatar>
                        </div>
                    </Grid>
                    <Grid item xs>
                        <Typography data-testid="name-component" sx={{ textTransform: "capitalize" }}>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Box>
    );
};

const StatsCard: FC<StatsCardProps> = ({ metric, stat }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography data-testid="metric-text" sx={{ fontSize: "20px", fontWeight: "600" }}>
                    {metric}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography
                        data-testid="stat-text"
                        sx={{
                            fontSize: "60px",
                            fontWeight: "600",
                            textAlign: "center",
                            color: metric === "Total Conversions" ? "success.main" : "auto",
                        }}
                    >
                        {stat}
                    </Typography>
                </Card>
            </Grid>
        </Grid>
    );
};

/****
 * 
 * This is a campaign card
 * 
 * 
 * Needs:
 * 
 * const [live, setLive] = useState(true);

    const changeStatus = () => {
        setLive(isLive => !isLive);
    };

 * @usage  
 * <CampaignCard
    name="test campaign"
    budget={100}
    live={live}
    leads={37}
    conversions={2}
    onChangeStatus={changeStatus}
    />
 */

const CampaignCard: FC<CampaignCardProps> = ({ campaign, onChangeStatus }) => {
    const { name, budget, status } = campaign;
    return (
        <Card data-testid="campaign-card" sx={{ margin: 1, textDecoration: "none" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>{name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ fontSize: "12px", color: "#938586" }}>
                        {formatCurrency(budget?.amount)}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        onChange={onChangeStatus}
                        value={status}
                        sx={{
                            "height": "32px",
                            "width": "100px",
                            "borderRadius": "48px",
                            "fontSize": "13px",
                            "background": status === "active" ? "#2E623F" : "#DED7D7",
                            "color": status === "active" ? "#FFFFFF" : "#3A2E2F",
                            "& .MuiSelect-icon": {
                                color: status === "active" ? "#FFFFFFB2" : "#3A2E2F3B",
                            },
                        }}
                        inputProps={{
                            style: {
                                fontSize: "13px",
                            },
                        }}
                    >
                        <MenuItem value="active">Live</MenuItem>
                        <MenuItem value="paused">Paused</MenuItem>
                        <MenuItem value="cancelled">Cancelled</MenuItem>
                        <MenuItem value="archived">Archived</MenuItem>
                    </Select>
                </Grid>
                {/* TO DO: campaign should have leads and conversion value */}
                {/* <Grid item md={6} xs={12}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>{leads} Leads</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "600", textAlign: "right" }}>
                        {conversions} Conversions
                    </Typography>
                </Grid> */}
            </Grid>
        </Card>
    );
};

const GridCard: FC<GridCardProps> = ({ grid, isSelected }) => {
    const navigate = useNavigate();
    const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
    const { workspaceId = "" } = useParams();
    const selectedGridId = useAppSelector(selectors.selectedGridId);
    const openMenu = Boolean(menuAnchor);
    const [archiveGrid] = useArchiveGridMutation();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [dispatch, actions] = useAppDispatch();

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const closeMenu = () => {
        setMenuAnchor(null);
    };

    const handleRemove = async () => {
        await archiveGrid({ workspaceId, gridId: grid.id });
        logEvent(EVENT_TYPES.REMOVE_GRID, { gridId: grid.id });
        enqueueSnackbar(`Removed ${grid.name}`, { variant: "success" });
    };

    const handleCardClick = () => {
        if (grid.id === selectedGridId) {
            // If the clicked grid is already selected, unselect it.
            dispatch(actions.setSelectedGrid(""));
            navigate(`/${workspaceId}/honeygrid`);
        } else {
            // If the clicked grid is not selected, select it.
            dispatch(actions.setSelectedGrid(grid.id));
            navigate(`/${workspaceId}/honeygrid?gridId=${grid.id}`);
        }
    };

    // Prevent the Card click event from propagating when IconButton is clicked
    const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Stop event propagation
        handleClickMenu(event); // Open the menu
    };

    return (
        <>
            <Card
                sx={{
                    "transition": "border-color 0.3s",
                    "&:hover": { background: theme => theme.palette.action.hover, cursor: "pointer" },
                    "border": isSelected ? theme => `2px solid ${theme.palette.primary.main}` : "2px solid transparent",
                }}
                onClick={handleCardClick}
                data-testid="grid-card-container"
            >
                <Grid container alignItems="center">
                    <Grid
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ pt: 0, overflow: "hidden", color: theme => theme.palette.text.primary }}
                        flexWrap="nowrap"
                    >
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="subtitle1"
                                noWrap
                                sx={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {grid.name}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton
                                onClick={handleMenuButtonClick}
                                role="grid-card-menu"
                                sx={{ color: theme => theme.palette.text.primary }}
                            >
                                <MoreHorizIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} sx={{ color: theme => theme.palette.text.secondary }}>
                        <TargetingAreasMetricRow grid={grid} />
                        <TargetingCoverageMetricRow grid={grid} />
                    </Grid>
                </Grid>
            </Card>
            <Menu anchorEl={menuAnchor} open={openMenu} onClose={closeMenu} role="grid-card-menu">
                <MenuList dense>
                    <MenuItem component={Link} to={grid.id}>
                        <ListItemIcon>
                            <EditIcon fontSize="small" sx={{ color: theme => theme.palette.text.primary }} />
                        </ListItemIcon>
                        <Typography fontSize="small">Edit</Typography>
                    </MenuItem>

                    <MenuItem
                        onClick={() => {
                            setDeleteConfirmationOpen(true);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <Typography fontSize="small" color="error">
                            Remove
                        </Typography>
                    </MenuItem>
                </MenuList>
            </Menu>
            <DeleteConfirmationDialog
                isOpen={deleteConfirmationOpen}
                title={`${grid.name}`}
                message={`Are you sure you want to remove ${grid.name}? Any active campaigns using this grid will no longer be able to update their targeting.`}
                onRemove={handleRemove}
                onCancel={() => setDeleteConfirmationOpen(false)}
            />
        </>
    );
};

export { Card, StaffCard, StatsCard, CampaignCard, GridCard };
export type { StaffCardProps, StatsCardProps, CampaignCardProps, GridCardProps };
