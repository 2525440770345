// For each type model we finish
// 1. Create RTK Query endpoints
// 3. Create firestore rules for each type
// 4. Add examples of queries and mutations to correct screens
// 2. Create mock data generators (test utils and storybook utils) faker.js
// 5. List any actions that need to be taken on the backend so we can write Firebase functions for them
import { CustomData } from ".";
import { Campaign } from "./Campaign";
import { EntityStatus, RoleObject } from "./common";
import { ReferenceFields } from "./geo";
import { SystemGeneratedString } from "./utility";

// Separating Workspace and BaseWorkspace enables us to have a
// draft Workspace to work with before it is saved to the database.
// When we retrieve a Workspace from the database, it will have an id.
export interface Workspace extends BaseWorkspace {
    id: string;
}
export interface BaseWorkspace {
    name: string;
    backgroundImage?: string;
    backgroundColor?: string;
    /**
     * Campaigns subCollection
     */
    campaigns?: Campaign[];
    /**
     * Lead tracking subCollection
     */
    leadsTracking?: LeadTracking[];
    /**
     * Grids subCollection
     */
    grids?: Grid[];
    /**
     * Asset libraries subCollection
     */
    libraries?: AssetLibrary[];
    /**
     * Integrations subCollection
     */
    integrations?: Integration[];
    /**
     * Linked location IDs
     */
    locations?: string[];
    createdAt?: string;
    roles: RoleObject;
    /**
     * TODO: How do we do payment methods? Is it one active payment method per workspace?
     * Stripe payments provided by the [stripe <=> Firebase extension](https://extensions.dev/extensions/invertase/firestore-stripe-payments)
     */
    payments?: any[];
    subscriptions?: any[];
    // accountId that links to an account which holds subscription, payment info, etc.
    accountId?: string;
    /**
     * Custom Data subcollection for storing user-provided data by h3 cell
     */
    customData?: CustomData[];
    /**
     * Metadata about the workspace such as when custom data was last updated
     */
    metadata?: {
        /**
         * ISO time string of when custom data was last updated
         */
        customDataUpdatedAt?: string;
    };
    status?: EntityStatus;
}

/**
 * Integration with third party ad platforms that enable campaign creation and management.
 * This interface expects the integration to be oauth 2.0 based
 * TODO: Ask Charlie what shape this should take
 */
export interface Integration {
    platform: "google" | "facebook";
    refreshToken: string;
    accessToken: string;
}

export interface Grid extends BaseGrid {
    id: SystemGeneratedString;
    createdAt: string;
    updatedAt: string;
}

export interface BaseGrid {
    name: string;
    radius?: number;
    manualFilters?: {
        inclusion?: string[];
        exclusion?: string[];
    };
    targetingFilters: ReferenceFieldFilters;
    center: { lat: Latitude; lng: Longitude };
    customTargetingAreas?: CustomTargetingArea;
    status?: EntityStatus;
    currentFilter: FilterID | null;
    /**
     * number of households in the targeting area
     */
    households?: number;
    targetingType: TargetingType;
    /**
     * percentage of households in the targeting area that are covered by the targeting filters
     * We store this value as the integer 0-1000 to avoid floating point errors
     * We divide by 1000 to get the percentage
     */
    targetingCoverage?: number;
    /**
     * number of households that are targeted by the targeting filters
     */
    targetedHouseholds?: number;
}

export enum TargetingType {
    RADIUS = "radius",
    COUNTY = "county",
    ZIPCODE = "zip code",
}

export type CustomTargetingArea = { [key in CustomTargetingAreaKeys]: string[] | undefined };
export type LegacyCustomTargetingArea = { type: CustomTargetingAreaKeys; ids: string[] };
export type CustomTargetingAreaKeys = TargetingType.COUNTY | TargetingType.ZIPCODE;

export type FilterID = string | ReferenceFields;
type Latitude = number;
type Longitude = number;

export type ReferenceFieldFilters = {
    [key in ReferenceFields | string]: Filter;
};

export type Filter = {
    percentage: number;
};

export interface AssetLibrary {
    id?: string;
    name: string;
    headlines: string[];
}

export interface Asset {
    id: string;
    url: string;
    type: AssetTypes;
    tags: string[];
}

enum AssetTypes {
    IMAGE = "image",
    VIDEO = "video",
    HEADLINE = "headline",
    DESCRIPTION = "description",
    CTA = "cta",
}

export interface LeadTracking {
    id?: string;
    name: string;
}
