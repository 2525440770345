import { EMAIL_STORAGE_KEY, FIRST_NAME_STORAGE_KEY, LAST_NAME_STORAGE_KEY } from "@/utils";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

import { defaultActionCodeSettings } from "./constants";

/**
 * Send Firebase Login Like to the email provides
 * @param email user's email address
 * @param redirect optional location that the user should be redirected to after accessing the magic link
 */
const sendMagicLink = async (email: string, redirect = "/") => {
    try {
        const auth = getAuth();
        let { url } = defaultActionCodeSettings;
        if (redirect) {
            url += `?redirect=${redirect}`;
        }

        await sendSignInLinkToEmail(auth, email, { ...defaultActionCodeSettings, url });
    } catch (e) {
        const error = e as { code: string; message: string };
        console.error(error.code, error.message);

        throw new Error("Unable to send sign in link");
    }
};

/**
 * Business logic for creating a new user account
 * @param email
 * @param fullName
 * @param redirectTo
 */
export const handleSignUpWithoutPassword = async (
    email: string,
    firstName: string,
    lastName: string,
    redirectTo?: string,
) => {
    await sendMagicLink(email, redirectTo);
    window.localStorage.setItem(EMAIL_STORAGE_KEY, email);
    window.localStorage.setItem(FIRST_NAME_STORAGE_KEY, firstName);
    window.localStorage.setItem(LAST_NAME_STORAGE_KEY, lastName);
};

/**
 * Actual business logic for signing into the app
 * @param email
 * @param redirectTo
 */
export const handleSignInWithoutPassword = async (email: string, redirectTo?: string) => {
    await sendMagicLink(email, redirectTo);
    window.localStorage.setItem(EMAIL_STORAGE_KEY, email);
};
