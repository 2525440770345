import { collection, getDocs, getFirestore, query, where } from "firebase/firestore";
import { PaymentLink } from "honeygrid-types";

import { Collections } from "../collections";
import { Builder } from "./types";

export const paymentLinksEndpoints = (builder: Builder, firestore = getFirestore()) => ({
    /**
     * A query that fetches a payment link based on the quantity of locations and the granularity of the subscription (monthly or yearly).
     * @param {number} locations The number of locations the user has.
     * @param { "monthly" | "yearly" } granularity The subscription type the user wants.
     */
    getPaymentLink: builder.query<PaymentLink, { locations: number; interval: PaymentLink["interval"] }>({
        queryFn: async ({ locations, interval }) => {
            const paymentLinkCollection = collection(firestore, Collections.PaymentLinks);
            const queryDef = query(
                paymentLinkCollection,
                where("quantity", "==", locations),
                where("interval", "==", interval),
                where("product", "==", "standard"),
            );
            const linkSnapshot = await getDocs(queryDef);
            if (linkSnapshot.empty) {
                throw new Error("No payment link found.");
            }
            const paymentLink = linkSnapshot.docs[0].data() as PaymentLink;
            return { data: paymentLink };
        },
    }),
});
