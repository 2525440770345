export enum Routes {
    Leads = "/leads",
    Metrics = "/metrics",
    Settings = "/settings",
    Locations = "/locations",
    Users = "/users",
    Default = "/",
    WildCard = "*",
    Login = "/login",
    Success = "/success",
    AcceptInvite = "acceptInvite",
    Dashboard = "dashboard",
}
