import { createSlice, nanoid } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Location, Optional } from "honeygrid-types";

import { authSlice } from "../auth/authSlice";
import { FeatureNames } from "../names";

export interface LocationState {
    locations: Record<string, Location>;
    locationsByWorkspaceId: Record<string, string[]>;
}

const initialState: LocationState = {
    locations: {},
    locationsByWorkspaceId: {},
};

export const locationSlice = createSlice({
    name: FeatureNames.Location,
    initialState,
    reducers: {
        createLocation: (state, action: PayloadAction<Optional<Location, "id">>) => {
            const id = action.payload.id ?? nanoid();
            const location = { ...action.payload, id };

            state.locations[id] = location;
        },
    },
    extraReducers: builder => {
        builder.addCase(authSlice.actions.logOut, () => initialState);
    },
});

export type locationActions = typeof locationSlice.actions;
