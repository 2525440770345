import { addDoc, collection, doc, getDoc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { BaseCampaign, Campaign, CampaignStatus, PartialWithRequiredId, SystemGeneratedString } from "honeygrid-types";

import { Collections } from "../collections";
import { campaignConverter } from "./converters";
import { Builder } from "./types";

export const campaignsEndpoints = (builder: Builder, firestore = getFirestore()) => ({
    /**
     * A query that retrieves all non-archived campaigns for a given workspace from the Firestore database.
     *
     * @param {{ workspaceId: string }} params The workspace ID to retrieve campaigns for.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getCampaigns"]} The RTK Query endpoint for retrieving all non-archived campaigns for a given workspace.
     */
    getCampaigns: builder.query<Campaign[], { workspaceId: string }>({
        queryFn: async ({ workspaceId }) => {
            if (!workspaceId) {
                console.debug("No workspaceId provided");
                return { data: [] as Campaign[] };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const campaignSubCollection = collection(workspaceDoc, Collections.Campaigns).withConverter(
                campaignConverter,
            );
            const docQuery = query(campaignSubCollection, where("status", "!=", CampaignStatus.ARCHIVED));
            const campaignsSnapshot = await getDocs(docQuery);
            const campaigns: Campaign[] = [];
            campaignsSnapshot.forEach(doc => {
                campaigns.push(doc.data());
            });
            return { data: campaigns };
        },
        providesTags: [Collections.Campaigns],
    }),
    /**
     * A query that retrieves a single campaign from the Firestore database by its ID and workspace ID.
     *
     * @param {{ workspaceId: string; campaignId: string }} params The workspace ID and campaign ID to retrieve.
     * @returns {ReturnType<typeof createApi>["endpoints"]["getCampaignById"]} The RTK Query endpoint for retrieving a single campaign by ID and workspace ID.
     */
    getCampaignById: builder.query<Campaign, { workspaceId: string; campaignId: string }>({
        queryFn: async ({ workspaceId, campaignId }) => {
            if (!workspaceId || !campaignId) {
                console.debug("Missing either workspaceId or campaignId");
                console.debug(`workspaceId: ${workspaceId}`);
                console.debug(`campaignId: ${campaignId}`);

                return { data: {} as Campaign };
            }
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const campaignDoc = doc(workspaceDoc, Collections.Campaigns, campaignId).withConverter(campaignConverter);
            const campaign = await getDoc(campaignDoc);
            // TODO: Handle undefined campaign
            return { data: campaign.data() as Campaign };
        },
        providesTags: (_result, _error, { campaignId }) => [{ type: Collections.Campaigns, campaignId }],
    }),
    /**
     * A mutation that creates a new campaign in the Firestore database.
     *
     * @param {BaseCampaign} campaign The campaign object to create.
     * @param {SystemGeneratedString} workspaceId The ID of the workspace that the campaign belongs to.
     * @returns {ReturnType<typeof createApi>["endpoints"]["createCampaign"]} The RTK Mutation endpoint for creating a new campaign.
     * @invalidates {Collections.Campaigns} Invalidates the `Collections.Campaigns` tag.
     * @invalidates {Collections.Campaigns, { type: Collections.Campaigns, id: campaign.workspaceId }} Invalidates the campaigns for the specific workspace being updated.
     */
    createCampaign: builder.mutation<Campaign, { campaign: BaseCampaign; workspaceId?: string }>({
        queryFn: async ({ campaign, workspaceId }) => {
            if (!workspaceId) {
                console.debug("No workspaceId provided");
                return { data: {} as Campaign };
            }
            const saveCampaign = { ...campaign };
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const campaignSubCollection = collection(workspaceDoc, Collections.Campaigns).withConverter(
                campaignConverter,
            );
            const docRef = await addDoc(campaignSubCollection, saveCampaign);
            const newCampaign = { ...campaign, id: docRef.id } as Campaign;
            return { data: newCampaign };
        },
        invalidatesTags: [Collections.Campaigns],
    }),
    /**
     * A mutation that updates a campaign in the Firestore database.
     *
     * @param {Partial<Campaign>} campaign The updated campaign object.
     * @param {SystemGeneratedString} workspaceId The ID of the workspace that the campaign belongs to.
     * @returns {ReturnType<typeof createApi>["endpoints"]["updateCampaign"]} The RTK Mutation endpoint for updating a campaign.
     * @invalidates {Collections.Campaigns} Invalidates the `Collections.Campaigns` tag.
     * @invalidates {Collections.Campaigns, { type: Collections.Campaigns, id: campaign.workspaceId }} Invalidates the campaigns for the specific workspace being updated.
     */
    updateCampaign: builder.mutation<
        Pick<Campaign, "id">,
        { workspaceId: SystemGeneratedString; campaign: PartialWithRequiredId<Campaign> }
    >({
        queryFn: async ({ workspaceId, campaign }) => {
            const saveCampaign = { ...campaign };
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const campaignDoc = doc(workspaceDoc, Collections.Campaigns, campaign.id).withConverter(campaignConverter);
            await updateDoc(campaignDoc, saveCampaign);
            return { data: { id: campaign.id } };
        },
        invalidatesTags: (_result, _error, { campaign }) => [
            Collections.Campaigns,
            { type: Collections.Campaigns, id: campaign.id },
        ],
    }),
    /**
     * A mutation that archives a campaign in the Firestore database.
     *
     * @param {Pick<Campaign, "id">} campaign The campaign object to delete.
     * @param {SystemGeneratedString} workspaceId The ID of the workspace that the campaign belongs to.
     * @returns {ReturnType<typeof createApi>["endpoints"]["deleteCampaign"]} The RTK Mutation endpoint for deleting a campaign.
     * @invalidates {Collections.Campaigns} Invalidates the `Collections.Campaigns` tag.
     * @invalidates {Collections.Campaigns, { type: Collections.Campaigns, id: campaign.workspaceId }} Invalidates the campaigns for the specific workspace being updated.
     */
    archiveCampaign: builder.mutation<
        Pick<Campaign, "id">,
        { workspaceId: SystemGeneratedString; campaignId: SystemGeneratedString }
    >({
        queryFn: async ({ workspaceId, campaignId }) => {
            const workspaceDoc = doc(firestore, Collections.Workspaces, workspaceId);
            const campaignDoc = doc(workspaceDoc, Collections.Campaigns, campaignId).withConverter(campaignConverter);
            await updateDoc(campaignDoc, { status: CampaignStatus.ARCHIVED });
            return { data: { id: campaignId } };
        },
        invalidatesTags: (_result, _error, { campaignId }) => [
            Collections.Campaigns,
            { type: Collections.Campaigns, id: campaignId },
        ],
    }),
});
