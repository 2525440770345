import { FC, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Logo } from "@/components/Logo";
import { Button, DialogContent, DialogTitle, Divider, Grid, Link, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";

import { ErrorNotification } from "../ErrorNotification";
import { MagicLinkView } from "../MagicLinkView";
import { handleSignUpWithoutPassword } from "../handlers";
import { emailValidator } from "../validators";

interface SignUpFormProps {
    onlySignUp?: boolean;
    onSignInClicked?: () => void;
    redirectUrl?: string;
}

/**
 * User signup data
 */
export interface UserSignup {
    firstName: string;
    lastName: string;
    email: string;
}

/**
 * Handles new user registration
 * @param onSignInClicked
 */
export const SignUpForm: FC<SignUpFormProps> = ({ onSignInClicked, redirectUrl, onlySignUp = false }) => {
    const [processError, setProcessError] = useState("");
    const [hasSignedUp, setHasSignedUp] = useState(false);
    const { pathname: pathName } = useLocation();
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState(searchParams.get("userEmail") ?? "");

    async function signUpUser(values: UserSignup, pathName: string) {
        try {
            await handleSignUpWithoutPassword(values.email, values.firstName, values.lastName, redirectUrl ?? pathName);
            setEmail(values.email);
            setHasSignedUp(true);
        } catch (error) {
            setProcessError((error as Error).message);
        }
    }

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: email ?? "",
        },
        validate: values => {
            const errors: Record<string, string> = {};
            if (!values.firstName && values.firstName.length < 1) {
                errors.firstName = "Required";
            }
            if (!values.lastName && values.lastName.length < 1) {
                errors.lastName = "Required";
            }
            if (!values.email && !emailValidator.safeParse(values.email).success) {
                errors.email = "Required";
            }
            return errors;
        },
        onSubmit: async values => {
            await signUpUser(values, pathName);
        },
    });

    return hasSignedUp ? (
        <MagicLinkView resend={() => signUpUser(formik.values, pathName)} email={formik.values.email} />
    ) : (
        <Grid container minWidth={400} flexDirection={"column"}>
            <DialogTitle>
                <Grid container flexDirection={"column"} alignItems={"center"}>
                    <Grid item>
                        <Logo />
                    </Grid>
                    <Grid item>
                        <Typography variant="caption">Precision marketing made simpler</Typography>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container item flexDirection={"column"} rowSpacing={4} mt={1}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid item>
                            <TextField
                                fullWidth
                                name="firstName"
                                onChange={formik.handleChange}
                                label="First name"
                                variant="outlined"
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                sx={{ mt: 2 }}
                                name="lastName"
                                onChange={formik.handleChange}
                                label="Last name"
                                variant="outlined"
                                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                helperText={formik.touched.lastName && formik.errors.lastName}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                fullWidth
                                sx={{ mt: 2 }}
                                name="email"
                                onChange={formik.handleChange}
                                label="Email"
                                variant="outlined"
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item mt={3}>
                            <Divider />
                        </Grid>
                        <Grid item>
                            <Button
                                fullWidth
                                sx={{ mt: 4 }}
                                disabled={!formik.isValid}
                                variant="contained"
                                type="submit"
                            >
                                Sign up
                            </Button>
                        </Grid>
                    </form>
                    {!onlySignUp && onSignInClicked && (
                        <Grid item container justifyContent={"center"}>
                            <Grid item>
                                <Typography variant="body2">
                                    Already have an account?
                                    <Link
                                        role="sign-in"
                                        onClick={() => onSignInClicked()}
                                        color="primary"
                                        sx={{ fontWeight: "bold", cursor: "pointer", ml: 1 }}
                                    >
                                        Sign in
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <ErrorNotification error={processError} clearError={() => setProcessError("")} />
                </Grid>
            </DialogContent>
        </Grid>
    );
};
