import React, { FC } from "react";

import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { useCreateMarketingUserMutation, useGetUserByIdQuery } from "@redux/services/firebaseService";
import { getAuth } from "firebase/auth";

import Elephant from "../../assets/images/Elephant.png";
import { IllustratedDialog } from "../IllustratedDialog";

interface ComingSoonDialogProps {
    isOpen: boolean;
    onDismiss: () => void;
}

export const ComingSoonDialog: FC<ComingSoonDialogProps> = ({ isOpen, onDismiss }) => {
    // default to not checked so people have to opt-in
    const [isChecked, setIsChecked] = React.useState(false);
    const [addMarketingUser] = useCreateMarketingUserMutation();
    const auth = getAuth();
    const { data: user } = useGetUserByIdQuery(auth.currentUser?.uid ?? "", { skip: !auth.currentUser?.uid });

    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    const handleOnPrimaryActionClicked = () => {
        if (user?.email && isChecked) {
            addMarketingUser({
                marketing: {
                    email: user.email,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    emailCampaignName: "coming-soon",
                },
            });
        }
        onDismiss();
    };

    return (
        <IllustratedDialog
            isOpen={isOpen}
            onDismiss={onDismiss}
            imageSource={Elephant}
            maxWidth={"sm"}
            title="Coming Soon"
            description="We're building something exciting for you! This feature is coming soon, we'll let you know once it's available."
            primaryAction={{
                label: "OK",
                onClick: handleOnPrimaryActionClicked,
            }}
        >
            <Grid item>
                <Grid container spacing={2} direction="column">
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox checked={isChecked} onChange={handleChange} />}
                            label={"Get notified about HoneyGrid features and updates."}
                        />
                    </FormGroup>
                </Grid>
            </Grid>
        </IllustratedDialog>
    );
};
