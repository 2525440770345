import { FC } from "react";

import { DialogContent, DialogTitle, Grid, Link, Stack, Typography } from "@mui/material";

import MagicBird from "../../../assets/images/MagicBird.png";

interface MagicLinkViewProps {
    email: string;
    resend?: () => Promise<void>;
}

/**
 * Success message component after sign in/up with MagicLink
 *
 * @param email
 */
export const MagicLinkView: FC<MagicLinkViewProps> = ({ email, resend }) => {
    return (
        <>
            <DialogTitle>Please check your inbox</DialogTitle>
            <DialogContent>
                <Grid container alignItems={"center"} flexDirection={"column"} rowSpacing={4} mt={1}>
                    <Typography sx={{ mt: 2, mb: 2 }}>
                        We've sent a magic link to you at <strong>{email}</strong>
                        <br />
                        It contains a link that'll sign you in super quick!
                    </Typography>
                    <Stack alignItems="center">
                        <img src={MagicBird} alt="successfully sent link" />
                    </Stack>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        No email in your inbox?
                        <Link
                            color="primary"
                            role="resend-link"
                            sx={{ textDecoration: "none", cursor: "pointer", ml: 1 }}
                            onClick={resend}
                        >
                            Resend it
                        </Link>
                    </Typography>
                </Grid>
            </DialogContent>
        </>
    );
};
