import { getAnalytics, isSupported, logEvent as logFirebaseEvent } from "firebase/analytics";

/**
 * Log an event to Firebase and a product analytics service
 * @param eventName
 * @param eventParams
 */
export const logEvent = async (eventName: string, eventParams?: any) => {
    try {
        if (await isSupported()) {
            const analytics = getAnalytics();
            logFirebaseEvent(analytics, eventName, eventParams);
        }
    } catch (error) {
        console.error(error);
    }
};

export const EVENT_TYPES = {
    GET_100_CLICK: "get_100_click",
    UPGRADE_CLICK: "upgrade_click",
    USER_LOGIN: "user_login",
    INVITE_USER_TO_WORKSPACE: "invite_user_to_workspace",
    REMOVE_USER_INVITE: "remove_user_invite",
    REMOVE_USER_FROM_WORKSPACE: "remove_user_from_workspace",
    SEARCH_LOCATION: "search_location",
    NEW_LOCATION: "new_location",
    LOCATION_ADDED_TO_WORKSPACE: "location_added_to_workspace",
    CREATE_WORKSPACE: "create_workspace",
    CREATE_GRID: "create_grid",
    UPDATE_GRID: "update_grid",
    REMOVE_GRID: "remove_grid",
    UPDATE_GRID_RADIUS: "update_grid_radius",
    TARGETING_FILTER_CLICK: "targeting_filter_click",
    REMOVE_TARGETING_FILTER: "remove_targeting_filter",
    UPDATE_TARGETING_FILTER: "update_targeting_filter",
    UPLOAD_CUSTOMER_DATA: "upload_customer_data",
    EXPORT_TARGETING: "export_targeting",
    SHOW_STATS_TABLE: "show_stats_table",
    CENTER_MAP_CLICK: "center_map_click",
    // CONNECTION EVENTS
    ARCHIVE_CONNECTION: "archive_connection",
    CREATE_CONNECTION: "create_connection",
    SHARE_CONNECTION: "share_connection",
};
