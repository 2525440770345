/**
 * Connection is the metadata about a connection to an external account
 * stored as a subcollection of Workspace
 */
export type Connection = {
    /**
     * Document ID from firestore
     */
    id: string;
    /**
     * User-defined name of the connection. Default's to the name of the external account
     */
    name: string;
    /**
     * Status of the connection
     */
    status: ConnectionStatus;
    /**
     * ID of the account in the external platform
     */
    accountId?: string;
    /**
     * Name of the account in the external platform
     */
    accountName?: string;
    /**
     * ID of the manager account in the external platform
     */
    managerAccountId?: string;
    /**
     * Name of the manager account in the external platform
     */
    managerAccountName?: string;
    /**
     * ID of the auth credentials in the integrationAuth collection
     */
    connectionAuthId?: string;
    platform: ConnectionPlatform; // the platform of the integration
    /**
     * When the connection was created as an ISO string
     */
    createdAt: string;
    /**
     * When the connection was last updated as an ISO string
     */
    updatedAt?: string;
    /**
     * When the connection was last synced as an ISO string
     */
    lastSyncedAt?: string;
    /**
     * Who created the connection
     */
    createdBy: string;
    rolesOwner: string[];
    rolesEditor: string[];
    rolesViewer: string[];
    /**
     * Number of cells in the connection
     */
    cellCount?: number;
};

export type BaseConnection = Omit<Connection, "id" | "createdAt" | "updatedAt" | "createdBy">;

export const ConnectionPlatforms = {
    GoogleAds: "googleAds",
    Facebook: "facebook",
} as const;

export type ConnectionPlatform = (typeof ConnectionPlatforms)[keyof typeof ConnectionPlatforms];

export const ConnectionStatus = {
    Active: "active",
    Archived: "archived",
    Error: "error",
    AwaitingAuth: "awaitingAuth",
    Pending: "pending",
} as const;

export const ConnectionStatusStringMap = {
    [ConnectionStatus.Active]: "Active",
    [ConnectionStatus.Archived]: "Archived",
    [ConnectionStatus.Error]: "Error",
    [ConnectionStatus.AwaitingAuth]: "Awaiting authentication",
    [ConnectionStatus.Pending]: "Awaiting setup",
};

export type ConnectionStatus = (typeof ConnectionStatus)[keyof typeof ConnectionStatus];

export type ConnectionAuth = {
    /**
     * Document ID from firestore
     */
    id: string;
    refreshToken: string;
    /**
     * IDs of all `connections` that consume this `connectionAuth`
     */
    connectionIds: string[];
    /**
     * Platform the connection is on
     */
    platform: ConnectionPlatform;
};

export type AccountSelectionListItemProps = {
    id: string;
    descriptiveName: string;
    parentName?: string;
    parentId?: string;
};
