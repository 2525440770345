import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BaseConnection, generateBaseConnection } from "honeygrid-types";

import { FeatureNames } from "../names";

export interface ConnectionState {
    connection?: BaseConnection; // Draft connection state
    connectionId: string | null; // Connection ID
}

const initialState: () => ConnectionState = () => {
    return { connection: generateBaseConnection({ empty: true }), connectionId: null };
};

export const connectionSlice = createSlice({
    name: FeatureNames.Connection,
    initialState,
    reducers: {
        resetConnection: state => {
            state.connection = generateBaseConnection({ empty: true });
        },
        setConnection: (state, action: PayloadAction<BaseConnection>) => {
            state.connection = action.payload;
        },
        setConnectionId: (state, action: PayloadAction<string | null>) => {
            state.connectionId = action.payload;
        },
    },
});

export type ConnectionSlice = typeof connectionSlice.actions;
