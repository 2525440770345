import { ReducersMapObject, combineReducers } from "@reduxjs/toolkit";

import { connectionSlice, gridSlice } from "./slices";
import { authSlice } from "./slices/auth/authSlice";
import { businessCustomerDataSlice } from "./slices/customerData/customerDataSlice";
import { locationSlice } from "./slices/location/locationSlice";
import { mapSlice } from "./slices/map/mapSlice";
import { FeatureNames } from "./slices/names";
import { themeSlice } from "./slices/theme/themeSlice";
import { uiSlice } from "./slices/ui/uiSlice";
import { workspaceSlice } from "./slices/workspace";

const defaultReducers = {
    [FeatureNames.Auth]: authSlice.reducer,
    [FeatureNames.BusinessCustomerData]: businessCustomerDataSlice.reducer,
    [FeatureNames.Location]: locationSlice.reducer,
    [FeatureNames.Map]: mapSlice.reducer,
    [FeatureNames.Theme]: themeSlice.reducer,
    [FeatureNames.UI]: uiSlice.reducer,
    [FeatureNames.Workspace]: workspaceSlice.reducer,
    [FeatureNames.Grid]: gridSlice.reducer,
    [FeatureNames.Connection]: connectionSlice.reducer,
} as const;

export const getCombinedReducer = <AdditionalState extends ReducersMapObject>(
    additionalReducers: AdditionalState = {} as AdditionalState,
) => {
    const combinedReducers = combineReducers<typeof defaultReducers & AdditionalState>({
        ...defaultReducers,
        ...additionalReducers,
    });
    return combinedReducers;
};

export const actions = {
    ...authSlice.actions,
    ...businessCustomerDataSlice.actions,
    ...locationSlice.actions,
    ...mapSlice.actions,
    ...themeSlice.actions,
    ...uiSlice.actions,
    ...workspaceSlice.actions,
    ...gridSlice.actions,
    ...connectionSlice.actions,
};
