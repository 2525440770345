import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import {
    Account,
    Campaign,
    Connection,
    CustomData,
    CustomTargetingArea,
    CustomTargetingAreaKeys,
    DataSource,
    Grid,
    InvitedUser,
    LegacyCustomTargetingArea,
    Location,
    Marketing,
    TargetingType,
    User,
    Workspace,
} from "honeygrid-types";

export const userConverter = {
    toFirestore(user: User): DocumentData {
        const { id: _id, ...data } = user;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): User {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as User;
    },
};

export const workspaceConverter = {
    toFirestore(workspace: Workspace): DocumentData {
        return workspace;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Workspace {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as Workspace;
    },
};

export const campaignConverter = {
    toFirestore(campaign: Campaign): DocumentData {
        const { id: _id, ...data } = campaign;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Campaign {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as Campaign;
    },
};

export const locationConverter = {
    toFirestore(location: Location): DocumentData {
        const { id: _id, ...data } = location;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Location {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as Location;
    },
};

export const gridConverter = {
    toFirestore(grid: Grid): DocumentData {
        const { id: _id, ...data } = grid;
        // Migrate old customTargetingAreas to new format
        const returnValue = structuredClone(data);
        if (Array.isArray(data.customTargetingAreas) && data.customTargetingAreas?.length > 0) {
            if (data.customTargetingAreas.some((area: LegacyCustomTargetingArea) => area.type === undefined)) {
                returnValue.customTargetingAreas = data.customTargetingAreas.reduce((acc, area) => {
                    if (area.type === undefined) {
                        return {
                            ...acc,
                            [TargetingType.COUNTY]: Array.isArray(area) ? area : ([area] as unknown as string[]),
                        };
                    } else {
                        return { ...acc, [area.type as CustomTargetingAreaKeys]: area.ids as string[] };
                    }
                }, {} as CustomTargetingArea);
            }
        } else if (Array.isArray(data.customTargetingAreas)) {
            // if the array is an empty array, still turn it into a customTargetingAreas object
            returnValue.customTargetingAreas = {} as CustomTargetingArea;
        }
        return returnValue;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Grid {
        const data = snapshot.data();
        // Migrate old customTargetingAreas to new format
        const returnValue = structuredClone(data);
        if (Array.isArray(data.customTargetingAreas) && data.customTargetingAreas?.length > 0) {
            if (data.customTargetingAreas.some((area: LegacyCustomTargetingArea) => area.type === undefined)) {
                returnValue.customTargetingAreas = data.customTargetingAreas.reduce((acc, area) => {
                    if (area.type === undefined) {
                        return {
                            ...acc,
                            [TargetingType.COUNTY]: Array.isArray(area) ? area : ([area] as unknown as string[]),
                        };
                    } else {
                        return { ...acc, [area.type as CustomTargetingAreaKeys]: area.ids as string[] };
                    }
                }, {} as CustomTargetingArea);
            }
        } else if (Array.isArray(data.customTargetingAreas)) {
            // if the array is an empty array, still turn it into a customTargetingAreas object
            returnValue.customTargetingAreas = {} as CustomTargetingArea;
        }
        const id = snapshot.id;
        return { ...returnValue, id } as Grid;
    },
};

export const customDataConverter = {
    toFirestore(customDatum: CustomData): DocumentData {
        const { id: _id, ...data } = customDatum;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): CustomData {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as CustomData;
    },
};

export const dataSourceConverter = {
    toFirestore(customDatum: DataSource): DocumentData {
        const { id: _id, ...data } = customDatum;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): DataSource {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as DataSource;
    },
};

export const marketingConverter = {
    toFirestore(marketing: Marketing): DocumentData {
        const { id: _id, ...data } = marketing;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Marketing {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as Marketing;
    },
};

export const accountConverter = {
    toFirestore(account: Account): DocumentData {
        const { id: _id, ...data } = account;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Account {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as Account;
    },
};

export const invitedUserConverter = {
    toFirestore(invitedUser: InvitedUser): DocumentData {
        const { id: _id, ...data } = invitedUser;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): InvitedUser {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as InvitedUser;
    },
};

export const connectionConverter = {
    toFirestore(customDatum: Connection): DocumentData {
        const { id: _id, ...data } = customDatum;
        return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Connection {
        const data = snapshot.data();
        const id = snapshot.id;
        return { ...data, id } as Connection;
    },
};
